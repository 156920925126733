import styles from '@/features/devices/tablets/components/actionButton/actionButton.module.scss';

import { Click } from '@/assets/sounds/soundsEffect';
import Button from '@/features/reusablecomponents/buttons/Button';
import { useItemsFound } from '@/hooks/useItemsFound';
import { useItemsSelected } from '@/hooks/useItemsSelected';
import { useTryCount } from '@/hooks/useTryCount';
import { handleGame4Validation } from '@/services/games/game4/gameFourHandleFunctions';
import {
    handleGoToGameStatus,
    handleNextOrPreviousOverlayDescription,
    handleValidation,
    handleValidateSelectedItems,
} from '@/services/global/globalHandleFunctions';
import { getNextGameStatus } from '@/services/global/globalUtils';
import { useGame4Store } from '@/store/games/game4Store';
import { useGameStore } from '@/store/gameStore';
import {
    type DeviceColor,
    type GameStatusActionWithoutGame4,
    GAMESTATUS,
} from '@/types/global/types';

type ActionType =
    | 'startGame'
    | 'goToNextOverlay'
    | 'goToNextGameStatus'
    | 'validation'
    | 'handleValidationSelection';

type ActionHandlers = {
    [K in ActionType]: () => void;
};

interface ActionButtonProps {
    title: string;
    action: ActionType;
    style?: React.CSSProperties;
    color: DeviceColor;
    soundPath?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({
    title,
    action,
    style,
    color,
    soundPath,
}) => {
    const currentGameStatus = useGameStore.use.gameStatus();

    const currentSituationIndex = useGame4Store.use.currentSituationIndex();

    const itemsSelected = useItemsSelected(
        currentGameStatus as GameStatusActionWithoutGame4
    );
    const itemsFound = useItemsFound(
        currentGameStatus as GameStatusActionWithoutGame4
    );
    const tryCount = useTryCount(currentGameStatus as GameStatusActionWithoutGame4);

    const actionHandlers: ActionHandlers = {
        startGame: () => handleGoToGameStatus(GAMESTATUS.INTRODUCTION),
        goToNextOverlay: () => handleNextOrPreviousOverlayDescription('next'),
        goToNextGameStatus: () => handleGoToGameStatus(getNextGameStatus()),
        validation: () =>
            currentGameStatus === GAMESTATUS.GAME4ACTION
                ? handleGame4Validation(currentSituationIndex)
                : handleValidation(itemsSelected, itemsFound, tryCount),
        handleValidationSelection: () =>
            handleValidateSelectedItems(itemsSelected, tryCount),
    };

    return (
        <div className={styles.buttonContainer} style={style}>
            <Button
                text={title}
                onClick={() => actionHandlers[action]()}
                soundPath={soundPath ?? Click}
                color={color}
            />
        </div>
    );
};

export default ActionButton;

import type React from 'react';
import styles from '@/features/reusablecomponents/bubblesAnimation/bubblesAnimation.module.scss';

const BubbleProperties = {
    verySmall: {
        size: 8,
    },
    small: {
        size: 16,
    },
    medium: {
        size: 24,
    },
    big: {
        size: 40,
    },
    veryBig: {
        size: 56,
    },
    huge: {
        size: 64,
    },
    veryHuge: {
        size: 80,
    },
};

const BubblesAnimation: React.FC<{
    color:
        | 'red'
        | 'green'
        | 'blue'
        | 'orange'
        | 'neutral'
        | 'error'
        | 'success'
        | 'unlock';
    numberOfBubbles: 10 | 29;
}> = ({ color, numberOfBubbles }) => {
    const randomBubbleProperty = (): { size: number } => {
        const bubblePropertyKeys = Object.keys(BubbleProperties);
        const randomProperty =
            bubblePropertyKeys[
                Math.floor(Math.random() * bubblePropertyKeys.length)
            ];
        return BubbleProperties[randomProperty as keyof typeof BubbleProperties];
    };

    return (
        <div>
            {Array.from(Array(numberOfBubbles).keys()).map((i) => {
                const { size } = randomBubbleProperty();
                const style = {
                    height: `${size}px`,
                    width: `${size}px`,
                    left: `${Math.random() * 94}%`,
                };

                return (
                    <div
                        key={i * Math.random() * Date.now()}
                        style={style}
                        className={styles[`${color}Bubble`]}
                    />
                );
            })}
        </div>
    );
};

export default BubblesAnimation;

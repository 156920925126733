import { type Character } from '@/types/games/game3/types';

/**
 * Custom type to get all values of an object, as same as enum values.
 */
export type ObjectValues<T> = T[keyof T];

/**
 * Custom type to enable completion of the snippet. Works as same as the original {@link Omit} type.
 */
export type StrictOmit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type Nullable<T> = T | null;

export type Maybe<T> = T | undefined;

export const GAMESTATUS = {
    WELCOME_SCREEN: 'WELCOME_SCREEN',
    INTRODUCTION: 'INTRODUCTION',

    GAME1INTRODUCTION: 'GAME1INTRODUCTION',
    GAME1ACTION: 'GAME1ACTION',
    GAME1PREVENTION: 'GAME1PREVENTION',
    GAME1SCORE: 'GAME1SCORE',
    GAME1UNLOCK: 'GAME1UNLOCK',

    GAME2INTRODUCTION: 'GAME2INTRODUCTION',
    GAME2ACTION: 'GAME2ACTION',
    GAME2PREVENTION: 'GAME2PREVENTION',
    GAME2SCORE: 'GAME2SCORE',
    GAME2UNLOCK: 'GAME2UNLOCK',

    GAME3INTRODUCTION: 'GAME3INTRODUCTION',
    GAME3ACTION: 'GAME3ACTION',
    GAME3PREVENTION: 'GAME3PREVENTION',
    GAME3SCORE: 'GAME3SCORE',
    GAME3UNLOCK: 'GAME3UNLOCK',

    GAME4INTRODUCTION: 'GAME4INTRODUCTION',
    GAME4ACTION: 'GAME4ACTION',
    GAME4PREVENTION: 'GAME4PREVENTION',
    GAME4SCORE: 'GAME4SCORE',
    GAME4UNLOCK: 'GAME4UNLOCK',

    FINISHED: 'FINISHED',
} as const;

export type GameStatus = ObjectValues<typeof GAMESTATUS>;

export type GameStatusAction =
    | typeof GAMESTATUS.GAME1ACTION
    | typeof GAMESTATUS.GAME2ACTION
    | typeof GAMESTATUS.GAME3ACTION
    | typeof GAMESTATUS.GAME4ACTION;

export type GameStatusActionWithoutGame4 = Exclude<
    GameStatusAction,
    typeof GAMESTATUS.GAME4ACTION
>;

export type DeviceColor = 'blue' | 'green' | 'orange' | 'red';
export type DeviceColorWithTV = DeviceColor | 'tv';
export type DeviceColorWithTvAndMediator = DeviceColorWithTV | 'mediator';

export enum OverlayType {
    NEUTRAL = 'neutral',
    SUCCESS = 'success',
    ERROR = 'error',
    UNLOCK = 'unlock',
}

export interface OverlayDescription {
    id?: number;
    title: string;
    text: string;
    character?: string;
    audio: string;
    job?: string;
    slug?: string;
}

export type HeaderContent = {
    title: string;
    text: string;
};

export type ButtonsInformations = {
    title: string;
    color: string;
    action: string;
};

export interface Item {
    id: number;
    slug: string;
    name: string;
    image: string;
}

export interface Message {
    id: number;
    title: string;
    text: string;
    audio: string;
}

export interface Product extends Item {
    successMessage?: Message[];
    errorMessage?: Message[];
}

export interface Molecule extends Item {
    description: string;
    code: string;
}

export interface Situation {
    id: number;
    text: string;
    audio: string;
    successMessage: Message[];
    errorMessage: Message[];
}

export type GoodCombinationKey = `${keyof ItemsSelected}Id`;

export type GoodCombination = {
    [key in GoodCombinationKey]?: number[] | number;
};

export type TryCountInfo = { mainItemId: number; count: number };

export interface ItemsSelected {
    products: Product;
    objects: Product;
    types: Item[];
    risks: Item[];
    pathways: Item;
    environments: Item;
    diseases: Item;
    characters: Character;
}

export interface ActionDatasJson {
    products: Product[];
    objects: Item[];
    types: Item[];
    risks: Item[];
    molecules: Molecule[];
    pathways: Item[];
    environments: Item[];
    diseases: Item[];
    characters: Character[];
    situations: Situation[];
}

import globalStyle from '@/assets/styles/app.module.scss';
import MediatorScreen from '@/features/devices/tablets/mediator/MediatorScreen';
import PreConnectionMediatorScreen from '@/features/devices/tablets/mediator/PreConnectionMediatorScreen';
import useReconnection from '@/hooks/useReconnection';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { emitSocketEvent } from '@/services/global/globalUtils';
import SocketService from '@/services/SocketService';
import { useDeviceStore } from '@/store/deviceStore';
import { useEffect } from 'react';

const MediatorTablet: React.FC<{ roomName: string }> = ({ roomName }) => {
    useReconnection();
    const socket = SocketService.getInstance().getSocket();

    const { mediator, blue, orange, red, green } =
        useDeviceStore.use.deviceStatus();

    useReSynchronizeDevice('mediator');

    useEffect(() => {
        document.title = `⭐ ${mediator.label}`;
        emitSocketEvent('send_synchronize_device', {
            deviceColor: 'mediator',
        });
    }, [mediator.label]);

    return (
        <div className={globalStyle.containerTablet}>
            {mediator.isActivated ? (
                <MediatorScreen />
            ) : (
                <PreConnectionMediatorScreen
                    deviceStatuses={[
                        { label: 'Bleu', status: blue.isConnected, color: 'blue' },
                        {
                            label: 'Orange',
                            status: orange.isConnected,
                            color: 'orange',
                        },
                        { label: 'Rouge', status: red.isConnected, color: 'red' },
                        {
                            label: 'Verte',
                            status: green.isConnected,
                            color: 'green',
                        },
                    ]}
                    handleConnect={() => {
                        socket.emit('send_start_game', {
                            roomName,
                            color: 'mediator',
                        });
                    }}
                />
            )}
        </div>
    );
};

export default MediatorTablet;

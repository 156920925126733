import { Logo } from '@/assets/images/global';
import styles from '@/features/devices/tv/components/welcome/welcomeScreen.module.scss';

const WelcomeScreen: React.FC = () => {
    return (
        <div className={styles.welcomeScreenContainer}>
            <img draggable={false} src={Logo} alt='logo' />
        </div>
    );
};

export default WelcomeScreen;

import { CardClick } from '@/assets/sounds/soundsEffect';
import styles from '@/features/devices/tv/components/card/vitalCard.module.scss';
import { handleItemSelected } from '@/services/global/globalHandleFunctions';
import { playSound } from '@/services/global/globalUtils';
import { motion } from 'framer-motion';
import type { Character } from '@/types/games/game3/types';
import { GAMESTATUS } from '@/types/global/types';

const VitalCard: React.FC<{ character: Character; isSelected: boolean }> = ({
    character,
    isSelected,
}) => {
    return (
        <motion.button
            className={`${styles.cardContainer} ${isSelected ? styles.selected : undefined}`}
            whileTap={{
                scale: 0.9,
            }}
            onClick={() => {
                handleItemSelected(character, 'characters', GAMESTATUS.GAME3ACTION);
                playSound(CardClick);
            }}
        >
            <div>
                <img
                    src={character.image}
                    alt='Character vital card'
                    draggable={false}
                />
                <div className={styles.cardVital}>
                    <p>{character.name}</p>
                    <p>{character.cardNumber}</p>
                </div>
            </div>
        </motion.button>
    );
};

export default VitalCard;

import type React from 'react';
import styles from '@/features/devices/tablets/components/header/header.module.scss';

interface HeaderProps {
    title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
    return (
        <div className={styles['headerContainer']}>
            <h1>{title}</h1>
        </div>
    );
};

export default Header;

import { ArrowBack } from '@/assets/images/global';
import actionDatas from '@/data/actionDatas.json';
import Header from '@/features/devices/tablets/components/header/Header';
import styles from '@/features/devices/tablets/orange/game2/game2OrangeScreen.module.scss';
import Keypad from '@/features/devices/tablets/orange/keypad/Keypad';
import { isObjectEmpty } from '@/services/global/globalUtils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MAX_CODE_LENGTH } from '@/sessionInfo/index';
import type { Molecule } from '@/types/global/types';

const Game2OrangeScreen: React.FC = () => {
    const [molecule, setMolecule] = useState<Molecule>({} as Molecule);
    const [code, setCode] = useState<string>('');

    const codes = useMemo(
        () => actionDatas.GAME2ACTION.molecules.map((molecule) => molecule.code),
        []
    );

    const handleGoBackButtonClick = useCallback(() => {
        setMolecule({} as Molecule);
        setCode('');
    }, []);

    useEffect(() => {
        if (code.length !== MAX_CODE_LENGTH) return;

        const foundMolecule = codes.includes(code)
            ? actionDatas.GAME2ACTION.molecules.find(
                  (molecule) => molecule.code === code
              )
            : undefined;

        if (foundMolecule) setMolecule(foundMolecule as Molecule);
        else setCode('');
    }, [code, codes]);

    return (
        <>
            <Header
                title={isObjectEmpty(molecule) ? code : `Molécule ${molecule.name}`}
            />
            {!isObjectEmpty(molecule) ? (
                <div className={styles.game2OrangeScreenContainer}>
                    <div className={styles.overlay}>
                        <h1>Description:</h1>
                        <p>{molecule?.description}</p>
                    </div>
                    <button
                        onClick={handleGoBackButtonClick}
                        className={styles.backButton}
                    >
                        <div />
                        <img draggable={false} src={ArrowBack} alt='arrow-back' />
                    </button>
                </div>
            ) : (
                <Keypad setCode={setCode} />
            )}
        </>
    );
};

export default Game2OrangeScreen;

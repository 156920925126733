import { useOverlayStore } from '@/store/overlayStore';

export default function useCurrentOverlay() {
    const {
        overlayDescriptions: currentOverlayDescriptions,
        currentOverlayIndex,
        overlayType,
    } = useOverlayStore();

    const currentTextAudio = currentOverlayDescriptions[currentOverlayIndex]?.audio;
    const currentTitle = currentOverlayDescriptions[currentOverlayIndex]?.title;
    const currentText = currentOverlayDescriptions[currentOverlayIndex]?.text;

    const isCurrentOverlay = currentOverlayDescriptions.length > 0;

    return {
        currentTextAudio,
        currentTitle,
        currentText,
        overlayType,
        isCurrentOverlay,
    };
}

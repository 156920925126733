import { emitSocketEvent } from '@/services/global/globalUtils';
import { useGameStore } from '@/store/gameStore';
import { type GameStatusActionWithoutGame4 } from '@/types/global/types';

export const handleResetItemsFound = () => {
    const gameStatus = useGameStore.getState()
        .gameStatus as GameStatusActionWithoutGame4;

    emitSocketEvent('send_reset_items_found', { gameStatus });
};

export const handleResetTryCount = () => {
    const gameStatus = useGameStore.getState()
        .gameStatus as GameStatusActionWithoutGame4;

    emitSocketEvent('send_reset_try_count', { gameStatus });
};

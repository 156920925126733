import createSelectors from '@/store/selectors';
import {
    resetStoreProperty,
    updateItemsFound,
    updateItemsSelected,
    updateTryCount,
} from '@/store/storeUtils';
import { deepCopy, INITIAL_GAME2_STORE } from '@/sessionInfo/sessionInfo';
import type { Game2ItemsSelected } from '@/types/games/game2/types';
import type { Item } from '@/types/global/types';
import { create } from 'zustand';

const useGame2StoreBase = create(() => deepCopy(INITIAL_GAME2_STORE));

export const useGame2Store = createSelectors(useGame2StoreBase);

export const setItemsSelectedGame2 = (
    itemKey: keyof Game2ItemsSelected,
    item: Item
) => updateItemsSelected(itemKey, item, useGame2StoreBase);

export const resetItemsSelectedGame2 = () =>
    resetStoreProperty('itemsSelected', useGame2StoreBase, INITIAL_GAME2_STORE);

export const setItemsFoundGame2 = (item: Item) =>
    updateItemsFound(item, useGame2StoreBase);

export const resetItemsFoundGame2 = () =>
    resetStoreProperty('itemsFound', useGame2StoreBase, INITIAL_GAME2_STORE);

export const setTryCountGame2 = (id: number) =>
    updateTryCount(id, useGame2StoreBase);

export const resetTryCountGame2 = () =>
    resetStoreProperty('tryCount', useGame2StoreBase, INITIAL_GAME2_STORE);

export const resetGame2Store = () => {
    useGame2StoreBase.setState(deepCopy(INITIAL_GAME2_STORE));
};

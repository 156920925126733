import {
    cloudsBottomBackground,
    cloudsTopBackground,
} from '@/assets/images/tablets/clouds';
import globalStyle from '@/assets/styles/app.module.scss';
import dataGlobal from '@/data/global.json';
import ActionButton from '@/features/devices/tablets/components/actionButton/ActionButton';
import CardContainerTablet from '@/features/devices/tablets/components/card/CardContainer';
import Header from '@/features/devices/tablets/components/header/Header';
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';
import tabletStyle from '@/features/devices/tablets/components/screens/tabletScreen.module.scss';
import VoteContainer from '@/features/devices/tablets/components/vote/VoteContainer';
import Game2OrangeScreen from '@/features/devices/tablets/orange/game2/Game2OrangeScreen';
import useCurrentTitleHeaderTablet from '@/hooks/useCurrentTitleHeaderTablet';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { useEffect } from 'react';
import { GAMESTATUS } from '@/types/global/types';

const deviceColor = 'orange';

const OrangeActionSwitch: React.FC = () => {
    const currentGameStatus = useGameStore.use.gameStatus();
    const currentHeaderTitle = useCurrentTitleHeaderTablet(deviceColor);
    const { orange } = useDeviceStore.use.deviceStatus();

    useReSynchronizeDevice('orange');

    useEffect(() => {
        document.title = `🟠 ${orange.label}`;
        emitSocketEvent('send_synchronize_device', {
            deviceColor: 'orange',
        });
    }, [orange.label]);

    const isTabletInactive = !orange.isActivated;

    const handleRender = () => {
        switch (currentGameStatus) {
            case GAMESTATUS.WELCOME_SCREEN:
                return (
                    <ActionButton
                        title={dataGlobal.buttons.begin}
                        action='startGame'
                        color='orange'
                    />
                );
                break;
            case GAMESTATUS.INTRODUCTION:
                return (
                    <ActionButton
                        title={dataGlobal.buttons.continue}
                        action='goToNextOverlay'
                        color='orange'
                    />
                );
            case GAMESTATUS.GAME1ACTION:
                return (
                    <CardContainerTablet
                        type={'products'}
                        deviceColor={deviceColor}
                    />
                );
            case GAMESTATUS.GAME2ACTION:
                return <Game2OrangeScreen />;
            case GAMESTATUS.GAME3ACTION:
                return (
                    <CardContainerTablet
                        type={'environments'}
                        deviceColor={deviceColor}
                    />
                );
            case GAMESTATUS.GAME4ACTION:
                return <VoteContainer color='orange' />;
            case GAMESTATUS.GAME2UNLOCK:
            case GAMESTATUS.GAME4PREVENTION:
                return (
                    <ActionButton
                        title={dataGlobal.buttons.continue}
                        action='goToNextOverlay'
                        color='orange'
                    />
                );
        }
    };

    return (
        <div className={globalStyle.containerTablet}>
            {isTabletInactive ? (
                <TabletScreen color='orange' />
            ) : (
                <div className={tabletStyle.orangeTabletContainer}>
                    <img
                        src={cloudsTopBackground.orange.big}
                        alt='Big clouds top'
                    />
                    <img
                        src={cloudsTopBackground.orange.small}
                        alt='Small clouds top'
                    />

                    {currentHeaderTitle && <Header title={currentHeaderTitle} />}

                    {handleRender()}
                    <img
                        draggable={false}
                        src={cloudsBottomBackground.orange}
                        alt='Clouds bottom'
                    />
                </div>
            )}
        </div>
    );
};

export default OrangeActionSwitch;

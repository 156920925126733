import styles from '@/features/devices/tv/components/header/header.module.scss';
import { extractDigitFromStatus } from '@/services/global/globalUtils';
import { useGameStore } from '@/store/gameStore';
import { motion } from 'framer-motion';

const headerVariants = {
    initial: { y: -10 },
    animate: { y: 0 },
};
const NUMBER_OF_STEPS = 4;

const HeaderTv = () => {
    const currentGameStatus = useGameStore.use.gameStatus();
    const currentGameStatusDigit = extractDigitFromStatus(currentGameStatus);

    return (
        <motion.div
            className={styles.headerTvContainer}
            variants={headerVariants}
            initial='initial'
            animate='animate'
            transition={{ bounce: 0.25, type: 'spring' }}
        >
            <div>
                <span>Étape {currentGameStatusDigit}</span>
            </div>
            <div>
                {Array.from({ length: NUMBER_OF_STEPS }).map((_, index) => (
                    <StatusElement
                        key={index}
                        index={index}
                        currentGameStatusDigit={currentGameStatusDigit}
                    />
                ))}
            </div>
        </motion.div>
    );
};

export default HeaderTv;

const StatusElement = ({
    index,
    currentGameStatusDigit,
}: {
    index: number;
    currentGameStatusDigit: number;
}) => {
    return (
        <div style={{ opacity: currentGameStatusDigit > index ? 1 : 0.2 }}>
            <p>E {index + 1}</p>
            <span />
        </div>
    );
};

import styles from '@/features/devices/tablets/components/vote/voteContainer.module.scss';

import Vote from '@/features/devices/tablets/components/vote/Vote';
import type { DeviceColor } from '@/types/global/types';
import { useGame4Store } from '@/store/games/game4Store';

enum VoteOption {
    Vrai = 'vrai',
    Faux = 'faux',
}

const VoteContainer: React.FC<{ color: DeviceColor }> = ({ color }) => {
    const playersAnswer = useGame4Store.use.playersAnswer();

    return (
        <>
            <div
                className={`${styles.lightBackground} ${playersAnswer[color] !== null ? styles.rotate : null}`}
            />
            <div className={styles.containerVoteContainer}>
                {Object.values(VoteOption).map((option) => (
                    <Vote
                        key={option}
                        text={option}
                        color={color}
                        isSelected={
                            playersAnswer[color] === (option === VoteOption.Vrai)
                        }
                    />
                ))}
            </div>
        </>
    );
};

export default VoteContainer;

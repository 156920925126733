import {
    cloudsBottomBackground,
    cloudsTopBackground,
} from '@/assets/images/tablets/clouds';
import { PhoneRinging } from '@/assets/sounds/soundsEffect';
import globalStyle from '@/assets/styles/app.module.scss';
import actionDeviceHeaderData from '@/data/actionDeviceHeaderContent.json';
import dataGlobal from '@/data/global.json';
import overlayData from '@/data/overlayDeviceContent.json';
import ActionButton from '@/features/devices/tablets/components/actionButton/ActionButton';
import buttonStyle from '@/features/devices/tablets/components/actionButton/actionButton.module.scss';
import Header from '@/features/devices/tablets/components/header/Header';
import TabletOverlay from '@/features/devices/tablets/components/overlay/TabletOverlay';
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';
import tabletStyle from '@/features/devices/tablets/components/screens/tabletScreen.module.scss';
import VoteContainer from '@/features/devices/tablets/components/vote/VoteContainer';
import AudioComponent from '@/features/reusablecomponents/global/AudioComponent';
import useCurrentTitleHeaderTablet from '@/hooks/useCurrentTitleHeaderTablet';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { handleNextOrPreviousOverlayDescription } from '@/services/global/globalHandleFunctions';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import { useEffect } from 'react';
import { GAMESTATUS } from '@/types/global/types';

const deviceColor = 'blue';

const BlueActionSwitch: React.FC = () => {
    const { blue } = useDeviceStore.use.deviceStatus();
    const currentGameStatus = useGameStore.use.gameStatus();
    const currentOverlayIndex = useOverlayStore.use.currentOverlayIndex();
    const currentTitle = useCurrentTitleHeaderTablet(deviceColor);

    useReSynchronizeDevice('blue');

    useEffect(() => {
        document.title = `🔵 ${blue.label}`;
        emitSocketEvent('send_synchronize_device', {
            deviceColor: 'blue',
        });
    }, [blue.label]);

    const isGameStatusIntroduction = [
        'GAME1INTRODUCTION',
        'GAME2INTRODUCTION',
        'GAME3INTRODUCTION',
        'GAME4INTRODUCTION',
    ].includes(currentGameStatus);

    const isTabletInactive = !blue.isActivated;

    const handleRender = () => {
        switch (currentGameStatus) {
            case GAMESTATUS.GAME1INTRODUCTION:
            case GAMESTATUS.GAME2INTRODUCTION:
            case GAMESTATUS.GAME3INTRODUCTION:
            case GAMESTATUS.GAME4INTRODUCTION:
                return currentOverlayIndex === 0 ? (
                    <>
                        <AudioComponent src={PhoneRinging} isLooping />
                        <button
                            onClick={() =>
                                handleNextOrPreviousOverlayDescription('next')
                            }
                            className={buttonStyle.hangUp}
                        />
                    </>
                ) : (
                    <ActionButton
                        title={dataGlobal.buttons.next}
                        action='goToNextOverlay'
                        color='blue'
                    />
                );
            case GAMESTATUS.GAME1SCORE:
            case GAMESTATUS.GAME2SCORE:
            case GAMESTATUS.GAME3SCORE:
            case GAMESTATUS.GAME4SCORE:
            case GAMESTATUS.GAME3UNLOCK:
                return (
                    <ActionButton
                        title={dataGlobal.buttons.continue}
                        action='goToNextGameStatus'
                        color='blue'
                    />
                );

            case GAMESTATUS.GAME1ACTION:
                return (
                    <TabletOverlay
                        title={`${overlayData.GAME1INTRODUCTION.tv[1].character} ${overlayData.GAME1INTRODUCTION.tv[1].job}`}
                        description={overlayData.GAME1INTRODUCTION.tv[1].text}
                    />
                );
            case GAMESTATUS.GAME2ACTION:
                return (
                    <TabletOverlay
                        title={`${overlayData.GAME2INTRODUCTION.tv[1].character} ${overlayData.GAME2INTRODUCTION.tv[1].job}`}
                        description={overlayData.GAME2INTRODUCTION.tv[1].text}
                    />
                );
            case GAMESTATUS.GAME3ACTION:
                return (
                    <TabletOverlay
                        title={`${overlayData.GAME3INTRODUCTION.tv[1].character} ${overlayData.GAME3INTRODUCTION.tv[1].job}`}
                        description={overlayData.GAME3INTRODUCTION.tv[1].text}
                    />
                );
            case GAMESTATUS.GAME4ACTION:
                return <VoteContainer color='blue' />;
        }
    };

    return (
        <div className={globalStyle.containerTablet}>
            {isTabletInactive ? (
                <TabletScreen color='blue' />
            ) : (
                <div className={tabletStyle.blueTabletContainer}>
                    <img
                        src={cloudsTopBackground.blue.big}
                        alt='Big clouds top'
                        draggable={false}
                    />
                    <img
                        src={cloudsTopBackground.blue.small}
                        alt='Small clouds top'
                        draggable={false}
                    />
                    {currentTitle && (
                        <Header
                            title={
                                isGameStatusIntroduction &&
                                currentOverlayIndex === 0
                                    ? actionDeviceHeaderData.GAME1INTRODUCTION.blue
                                          .headerContentPhone.title
                                    : currentTitle
                            }
                        />
                    )}
                    {handleRender()}
                    <img
                        src={cloudsBottomBackground.blue}
                        alt='Clouds bottom'
                        draggable={false}
                    />
                </div>
            )}
        </div>
    );
};

export default BlueActionSwitch;

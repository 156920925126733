import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import { handleUpdateDeviceActivatedStatus } from '@/services/global/globalHandleFunctions';
import { useDeviceStore } from '@/store/deviceStore';
import type React from 'react';
import type { DeviceColor } from '@/types/global/types';

const ToggleTabletStatus: React.FC = () => {
    const deviceStatuses = useDeviceStore.use.deviceStatus();

    return (
        <div>
            {Object.entries(deviceStatuses).map(([key, { isActivated, label }]) => {
                if (key === 'mediator' || key === 'tv') return null;
                return (
                    <MediatorAction
                        key={label}
                        HandleFunctions={() =>
                            handleUpdateDeviceActivatedStatus(
                                key as DeviceColor,
                                !isActivated
                            )
                        }
                        title={`${isActivated ? 'Désactiver' : 'Activer'} la tablette ${label}`}
                        description={`Cette action ${isActivated ? 'désactive' : 'active'} la tablette ${label}.`}
                        displayingCondition={true}
                    />
                );
            })}
        </div>
    );
};

export default ToggleTabletStatus;

import {
    cloudsBigBackground,
    cloudsMediumBackground,
    cloudsSmallBackground,
} from '@/assets/images/tablets/clouds';
import styles from '@/features/devices/tablets/components/screens/tabletScreen.module.scss';
import BubblesAnimation from '@/features/reusablecomponents/bubblesAnimation/BubblesAnimation';
import { NUMBER_OF_BUBBLES_TABLET } from '@/sessionInfo/index';
import { type DeviceColor } from '@/types/global/types';

interface TabletScreenProps {
    color: DeviceColor;
    children?: React.ReactNode;
}

const TabletScreen: React.FC<TabletScreenProps> = ({ color, children }) => {
    return (
        <div className={`${styles[`${color}TabletInactiveContainer`]}`}>
            <img draggable={false} src={cloudsBigBackground[color]} alt='cloud' />
            <BubblesAnimation
                color={color}
                numberOfBubbles={NUMBER_OF_BUBBLES_TABLET}
            />
            <img
                src={cloudsMediumBackground[color]}
                alt='cloud'
                draggable={false}
            />
            <BubblesAnimation
                color={color}
                numberOfBubbles={NUMBER_OF_BUBBLES_TABLET}
            />
            <img
                src={cloudsSmallBackground[color]}
                alt='clouds'
                draggable={false}
            />
            {children}
        </div>
    );
};

export default TabletScreen;

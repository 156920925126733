import styles from '@/features/devices/tablets/components/vote/vote.module.scss';

import { emitSocketEvent } from '@/services/global/globalUtils';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import type { DeviceColor } from '@/types/global/types';

interface VoteProps {
    text: 'vrai' | 'faux';
    color: DeviceColor;
    isSelected: boolean;
}

const Vote: React.FC<VoteProps> = ({ text, color, isSelected }) => {
    const handleClick = () => {
        emitSocketEvent('send_update_players_answer_game_4', {
            deviceColor: color,
            value: text === 'vrai',
        });
    };

    const className = useMemo(() => {
        return `${
            text === 'vrai' ? styles.trueVoteContainer : styles.falseVoteContainer
        } ${isSelected ? styles.selected : ''}`;
    }, [text, isSelected]);

    return (
        <motion.button
            className={className}
            whileTap={{
                scale: 0.9,
            }}
            onClick={handleClick}
        >
            <div />
        </motion.button>
    );
};

export default Vote;

import goodCombinationsData from '@/data/goodCombinations.json';
import type { Game2ItemsSelected } from '@/types/games/game2/types';
import { GAMESTATUS, type Message, OverlayType } from '@/types/global/types';

export const validateGame2Action = (
    itemsSelected: Game2ItemsSelected
): { message?: Message[]; type: OverlayType } => {
    const goodCombinations = goodCombinationsData[GAMESTATUS.GAME2ACTION];
    const { objects: selectedObject, pathways: selectedPathway } = itemsSelected;

    const isValidated = goodCombinations.some(
        (combination) =>
            selectedObject.id === combination.objectsId &&
            selectedPathway.id === combination.pathwaysId
    );

    return {
        message: isValidated
            ? selectedObject.successMessage
            : selectedObject.errorMessage,
        type: isValidated ? OverlayType.SUCCESS : OverlayType.ERROR,
    };
};

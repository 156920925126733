import actionDatas from '@/data/actionDatas.json';
import mediatorDatas from '@/data/mediator/actionsGames.json';

import { type ActionDatasJson, type GameStatus } from '@/types/global/types';
import type { ActionsGamesMapping } from '@/services/mediator/types.mediator';

export const actionsGames: ActionsGamesMapping = mediatorDatas;

export const datasGameStatus = (gameStatus: GameStatus): ActionDatasJson =>
    actionDatas[gameStatus as keyof typeof actionDatas] as ActionDatasJson;

export const getTextFromCurrentGameStatus = (gameStatus: GameStatus): string =>
    actionsGames[gameStatus].title;

import {
    BigClouds,
    BottomBigCloudsGreen,
    BottomBigCloudsRed,
    BottomSmallCloudsGreen,
    BottomSmallCloudsRed,
    SmallClouds,
    TopLeftCloudsRed,
} from '@/assets/images/tv';
import styles from '@/features/devices/tv/components/cloudsBackground/cloudsBackground.module.scss';
import BubblesAnimation from '@/features/reusablecomponents/bubblesAnimation/BubblesAnimation';
import { NUMBER_OF_BUBBLES_TV } from '@/sessionInfo/index';
import { type OverlayType } from '@/types/global/types';

const CloudsBackground: React.FC<{
    overlayType: OverlayType;
}> = ({ overlayType }) => {
    const handleRender = () => {
        switch (overlayType) {
            case 'success':
                return (
                    <>
                        <img
                            src={BottomBigCloudsGreen}
                            alt='nuages'
                            className={styles.bottomRightClouds}
                        />
                        <img
                            src={BottomSmallCloudsGreen}
                            alt='nuages'
                            className={styles.bottomRightClouds}
                        />
                    </>
                );
            case 'error':
                return (
                    <>
                        <img
                            src={BottomBigCloudsRed}
                            alt='nuages'
                            className={styles.bottomRightClouds}
                        />
                        <img
                            src={BottomSmallCloudsRed}
                            alt='nuages'
                            className={styles.bottomRightClouds}
                        />
                        <img
                            src={TopLeftCloudsRed}
                            alt='nuages'
                            className={styles.topLeftClouds}
                        />
                    </>
                );
            case 'unlock':
                return (
                    <BubblesAnimation
                        color={'unlock'}
                        numberOfBubbles={NUMBER_OF_BUBBLES_TV}
                    />
                );
            case 'neutral':
                return (
                    <>
                        <img
                            src={SmallClouds}
                            alt='nuages'
                            className={styles.bottomLeftClouds}
                        />
                        <img
                            src={BigClouds}
                            alt='nuages'
                            className={styles.bottomLeftClouds}
                        />
                    </>
                );
            default:
                return (
                    <BubblesAnimation
                        color={'neutral'}
                        numberOfBubbles={NUMBER_OF_BUBBLES_TV}
                    />
                );
        }
    };

    return (
        <div className={styles.cloudsBackgroundContainer}>
            <BubblesAnimation
                color={overlayType}
                numberOfBubbles={NUMBER_OF_BUBBLES_TV}
            />
            {handleRender()}
        </div>
    );
};

export default CloudsBackground;

import createSelectors from '@/store/selectors';
import { resetStoreProperty } from '@/store/storeUtils';
import { deepCopy, INITIAL_GAME4_STORE } from '@/sessionInfo/sessionInfo';
import type { DeviceColor } from '@/types/global/types';
import { create } from 'zustand';

const useGame4StoreBase = create(() => deepCopy(INITIAL_GAME4_STORE));

export const useGame4Store = createSelectors(useGame4StoreBase);

export const setSituationIndexGame4 = (currentSituationIndex: number) => {
    useGame4StoreBase.setState(() => ({
        currentSituationIndex,
    }));
};

export const setPlayersAnswerGame4 = (color: DeviceColor, value: boolean) => {
    useGame4StoreBase.setState((state) => ({
        playersAnswer: { ...state.playersAnswer, [color]: value },
    }));
};

export const resetPlayersAnswerGame4 = () =>
    resetStoreProperty('playersAnswer', useGame4StoreBase, INITIAL_GAME4_STORE);

export const resetSituationIndexGame4 = () =>
    resetStoreProperty(
        'currentSituationIndex',
        useGame4StoreBase,
        INITIAL_GAME4_STORE
    );

export const resetGame4Store = () => {
    useGame4StoreBase.setState(deepCopy(INITIAL_GAME4_STORE));
};

import { motion } from 'framer-motion';
import styles from '@/features/devices/tablets/components/overlay/tabletOverlay.module.scss';

interface TabletOverlayProps {
    title?: string;
    description?: string;
}

const containerVariants = {
    hidden: { opacity: 0, y: 600 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.3,
        },
    },
};

const TabletOverlay: React.FC<TabletOverlayProps> = ({ title, description }) => {
    return (
        <motion.div
            className={styles.tabletOverlay}
            variants={containerVariants}
            initial='hidden'
            animate='visible'
        >
            <h1>{title}</h1>
            <p>{description}</p>
        </motion.div>
    );
};

export default TabletOverlay;

import styles from '@/features/devices/tv/components/timer/timer.module.scss';
import { useEffect, useState } from 'react';

const Timer: React.FC<{ time: number; callback: () => void }> = ({
    time,
    callback,
}) => {
    const [countDown, setCountDown] = useState(time);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown((oldValue) => oldValue - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    if (countDown < 0) {
        callback();
    }

    return (
        <div className={styles.timerContainer}>
            <p>{countDown}</p>
        </div>
    );
};

export default Timer;

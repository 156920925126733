import { Placeholder } from '@/assets/images/global';
import styles from '@/features/devices/tv/components/card/card.module.scss';
import { useOverlayStore } from '@/store/overlayStore';
import type { Item } from '@/types/global/types';

interface CardProps {
    selectedItem?: Item;
    variant?: 'outline' | 'inner';
    size?: 'big' | 'small';
    title?: string;
}

export const Card: React.FC<CardProps> = ({
    selectedItem,
    variant,
    size = 'big',
    title,
}) => {
    const overlayType = useOverlayStore.use.overlayType();

    const isItemCharacter =
        selectedItem && ('successMessage' && 'description') in selectedItem;

    return (
        <div className={title ? styles.cardWrapper : undefined}>
            {variant === 'outline' && title ? <p>{title}</p> : undefined}
            <div
                className={`${styles[`${size}-${variant}-container`]} ${overlayType === 'error' ? styles.error : styles.neutral} ${isItemCharacter ? styles.character : undefined}`}
            >
                <img
                    src={(selectedItem && selectedItem.image) ?? Placeholder}
                    alt='Item'
                />
                {variant === 'inner' && !isItemCharacter ? (
                    <p>{selectedItem?.name ?? title} </p>
                ) : undefined}
            </div>
        </div>
    );
};

export default Card;

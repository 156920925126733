import { deepCopy } from '@/sessionInfo/sessionInfo';
import type { Game1Store } from '@/types/games/game1/types';
import type { Game2Store } from '@/types/games/game2/types';
import type { Game3Store } from '@/types/games/game3/types';
import type { Game4Store } from '@/types/games/game4/types';
import type { Item } from '@/types/global/types';
import type { StoreApi, UseBoundStore } from 'zustand';

export const updateTryCount = <TStore extends Game1Store | Game2Store | Game3Store>(
    id: number,
    baseStore: UseBoundStore<StoreApi<TStore>>
) => {
    baseStore.setState((state) => {
        const newTryCount = [...state.tryCount];
        const existingObject = newTryCount.find((item) => item.mainItemId === id);

        if (existingObject) existingObject.count += 1;
        else newTryCount.push({ mainItemId: id, count: 1 });

        return {
            tryCount: newTryCount,
        } as TStore;
    });
};

export const updateItemsFound = <
    TStore extends Game1Store | Game2Store | Game3Store,
>(
    item: Item,
    baseStore: UseBoundStore<StoreApi<TStore>>
) => {
    baseStore.setState((state) => {
        const newItemsFound = [...state.itemsFound];
        const firstEmpltyIndex = newItemsFound.findIndex((item) => item === null);

        if (firstEmpltyIndex === -1) return baseStore.getState();

        newItemsFound[firstEmpltyIndex] = item;

        return {
            itemsFound: newItemsFound,
        } as TStore;
    });
};

export const updateItemsSelected = <TStore extends Game2Store | Game3Store>(
    itemKey: keyof TStore['itemsSelected'],
    item: Item,
    baseStore: UseBoundStore<StoreApi<TStore>>
) => {
    baseStore.setState(
        (state) =>
            ({
                itemsSelected: { ...state.itemsSelected, [itemKey]: item },
            }) as TStore
    );
};

export const resetStoreProperty = <
    TStore extends Game1Store | Game2Store | Game3Store | Game4Store,
>(
    property: keyof TStore,
    baseStore: UseBoundStore<StoreApi<TStore>>,
    initialState: TStore
) => {
    baseStore.setState(
        () =>
            ({
                [property]: deepCopy(initialState[property]),
            }) as Partial<TStore>
    );
};

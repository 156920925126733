import {
    cloudsBottomBackground,
    cloudsTopBackground,
} from '@/assets/images/tablets/clouds';
import globalStyle from '@/assets/styles/app.module.scss';
import dataGlobal from '@/data/global.json';
import ActionButton from '@/features/devices/tablets/components/actionButton/ActionButton';
import CardContainerTablet from '@/features/devices/tablets/components/card/CardContainer';
import CardContainerVital from '@/features/devices/tablets/components/card/CardContrainerVital';
import Header from '@/features/devices/tablets/components/header/Header';
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';
import tabletStyle from '@/features/devices/tablets/components/screens/tabletScreen.module.scss';
import VoteContainer from '@/features/devices/tablets/components/vote/VoteContainer';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import useCurrentTitleHeaderTablet from '@/hooks/useCurrentTitleHeaderTablet';
import { useItemsSelected } from '@/hooks/useItemsSelected';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import { useEffect } from 'react';
import { GAMESTATUS, type GameStatusActionWithoutGame4 } from '@/types/global/types';

const deviceColor = 'green';
const GreenActionSwitch: React.FC = () => {
    const { green } = useDeviceStore.use.deviceStatus();
    const currentGameStatus = useGameStore.use.gameStatus();
    const { isCurrentOverlay } = useCurrentOverlay();
    const overlayType = useOverlayStore.use.overlayType();
    const currentHeaderTitle = useCurrentTitleHeaderTablet(deviceColor);
    const itemsSelected = useItemsSelected(
        currentGameStatus as GameStatusActionWithoutGame4
    );

    useReSynchronizeDevice('green');

    useEffect(() => {
        document.title = `🟢 ${green.label}`;
        emitSocketEvent('send_synchronize_device', {
            deviceColor: 'green',
        });
    }, [green.label]);

    const isTabletInactive = !green.isActivated;

    const handleRender = () => {
        switch (currentGameStatus) {
            case GAMESTATUS.GAME1UNLOCK:
                return (
                    <ActionButton
                        title={dataGlobal.buttons.continue}
                        action='goToNextGameStatus'
                        color='green'
                    />
                );
            case GAMESTATUS.GAME1ACTION:
                return (
                    <CardContainerTablet type={'types'} deviceColor={deviceColor} />
                );
            case GAMESTATUS.GAME2ACTION:
                if (isCurrentOverlay) {
                    return (
                        <ActionButton
                            title={
                                overlayType === 'success' ? 'Suivant' : 'Réessayer'
                            }
                            action='validation'
                            color='green'
                        />
                    );
                }
                return (
                    <CardContainerTablet
                        type={'objects'}
                        deviceColor={deviceColor}
                    />
                );
            case GAMESTATUS.GAME3ACTION:
                if (!itemsSelected.environments) {
                    return null;
                }
                return <CardContainerVital />;
            case GAMESTATUS.GAME3PREVENTION:
                return (
                    <ActionButton
                        title={dataGlobal.buttons.next}
                        action='goToNextOverlay'
                        color='green'
                    />
                );
            case GAMESTATUS.GAME4ACTION:
                return !isCurrentOverlay ? (
                    <VoteContainer color='green' />
                ) : (
                    <ActionButton
                        color='green'
                        title='Continuer'
                        action='validation'
                    />
                );
            case GAMESTATUS.GAME4UNLOCK:
                return (
                    <ActionButton
                        title={dataGlobal.buttons.continue}
                        action='goToNextGameStatus'
                        color='green'
                    />
                );
            case GAMESTATUS.FINISHED:
                break;
        }
    };

    return (
        <div className={globalStyle.containerTablet}>
            {isTabletInactive ? (
                <TabletScreen color='green' />
            ) : (
                <div className={tabletStyle.greenTabletContainer}>
                    <img
                        draggable={false}
                        src={cloudsTopBackground.green.big}
                        alt='Big clouds top'
                    />
                    <img
                        draggable={false}
                        src={cloudsTopBackground.green.small}
                        alt='Small clouds top'
                    />

                    {currentHeaderTitle && <Header title={currentHeaderTitle} />}

                    {handleRender()}
                    <img
                        draggable={false}
                        src={cloudsBottomBackground.green}
                        alt='Clouds bottom'
                    />
                </div>
            )}
        </div>
    );
};

export default GreenActionSwitch;

import { useGame1Store } from '@/store/games/game1Store';
import { useGame2Store } from '@/store/games/game2Store';
import { useGame3Store } from '@/store/games/game3Store';
import { useMemo } from 'react';
import {
    GAMESTATUS,
    type GameStatusActionWithoutGame4,
    type Item,
} from '@/types/global/types';

export const useItemsFound = (gameStatus: GameStatusActionWithoutGame4): Item[] => {
    const itemsFoundGame1 = useGame1Store.use.itemsFound();
    const itemsFoundGame2 = useGame2Store.use.itemsFound();
    const itemsFoundGame3 = useGame3Store.use.itemsFound();

    const itemsFound = useMemo(
        () => ({
            [GAMESTATUS.GAME1ACTION]: itemsFoundGame1,
            [GAMESTATUS.GAME2ACTION]: itemsFoundGame2,
            [GAMESTATUS.GAME3ACTION]: itemsFoundGame3,
        }),
        [itemsFoundGame1, itemsFoundGame2, itemsFoundGame3]
    );

    return itemsFound[gameStatus];
};

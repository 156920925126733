import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import StatusIndicator from '@/features/devices/tablets/mediator/StatusIndicator';
import styles from '@/features/devices/tablets/mediator/mediatorScreen.module.scss';

import datas from '@/data/mediator/actionsGames.json';
import {
    displayPreventMessage,
    displayScore,
    displayUnlockCode,
    gamesAccess,
    resetGame,
} from '@/data/mediator/genericDatas.json';
import RenderComponentGameAction4 from '@/features/devices/tablets/mediator/gamesComponents/RenderComponentGameAction4';
import ToggleTabletStatus from '@/features/devices/tablets/mediator/gamesComponents/ToggleTabletStatus';
import {
    handleGoToGameStatus,
    handleNextOrPreviousOverlayDescription,
    handleReset,
    handleSendGameStatus,
    handleTabletStatus,
} from '@/services/global/globalHandleFunctions';
import {
    emitSocketEvent,
    getNextGameStatus,
    objectKeys,
} from '@/services/global/globalUtils';
import { getTextFromCurrentGameStatus } from '@/services/mediator/mediatorUtils';
import type { ActionsGamesMapping } from '@/services/mediator/types.mediator';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import {
    GAMESTATUS,
    type DeviceColorWithTvAndMediator,
    type GameStatus,
} from '@/types/global/types';
import RenderComponentGamesActions from './gamesComponents/RenderComponentGamesActions';

const MediatorScreen: React.FC = () => {
    const gameStatus = useGameStore.use.gameStatus();
    const allDevices = useDeviceStore.use.deviceStatus();
    const currentOverlayIndex = useOverlayStore.use.currentOverlayIndex();

    const actionsGames: ActionsGamesMapping = datas;
    const btnActions = actionsGames[gameStatus].actions;

    const handleReconnection = (device: DeviceColorWithTvAndMediator) => {
        emitSocketEvent('send_synchronize_device', {
            deviceColor: device,
        });
    };

    const isFirstsThreeGameAction =
        gameStatus === GAMESTATUS.GAME1ACTION ||
        gameStatus === GAMESTATUS.GAME2ACTION ||
        gameStatus === GAMESTATUS.GAME3ACTION;

    const isIntroductionGame =
        gameStatus === GAMESTATUS.INTRODUCTION ||
        gameStatus === GAMESTATUS.GAME1INTRODUCTION ||
        gameStatus === GAMESTATUS.GAME2INTRODUCTION ||
        gameStatus === GAMESTATUS.GAME3INTRODUCTION ||
        gameStatus === GAMESTATUS.GAME4INTRODUCTION;

    const isPreventionMessage =
        gameStatus === GAMESTATUS.GAME1PREVENTION ||
        gameStatus === GAMESTATUS.GAME2PREVENTION ||
        gameStatus === GAMESTATUS.GAME3PREVENTION ||
        gameStatus === GAMESTATUS.GAME4PREVENTION;

    const isScorePage =
        gameStatus === GAMESTATUS.GAME1SCORE ||
        gameStatus === GAMESTATUS.GAME2SCORE ||
        gameStatus === GAMESTATUS.GAME3SCORE ||
        gameStatus === GAMESTATUS.GAME4SCORE;

    const isUnlockPage =
        gameStatus === GAMESTATUS.GAME1UNLOCK ||
        gameStatus === GAMESTATUS.GAME2UNLOCK ||
        gameStatus === GAMESTATUS.GAME3UNLOCK ||
        gameStatus === GAMESTATUS.GAME4UNLOCK;

    const handleClick = (action: string | undefined) => {
        switch (action) {
            case 'initGame':
                handleSendGameStatus(GAMESTATUS.INTRODUCTION);
                break;
            case 'previous':
            case 'next':
                handleNextOrPreviousOverlayDescription(action);
                break;
            case 'displayUnlockCode':
                handleGoToGameStatus(
                    actionsGames[gameStatus]?.unlockCodeStatus as GameStatus
                );
                break;
            case 'displayScore':
                handleGoToGameStatus(
                    actionsGames?.[gameStatus]?.scoreStatus as GameStatus
                );
                break;
            case 'nextGame':
                handleGoToGameStatus(getNextGameStatus());
                break;
            case 'displayPreventMessage':
                handleGoToGameStatus(
                    actionsGames?.[gameStatus]?.preventStatus as GameStatus
                );
                break;
            case 'desactivateAllTablets':
                handleTabletStatus({
                    blue: false,
                    green: false,
                    orange: false,
                    red: false,
                });
                break;
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Écran Médiateur</h1>
            </div>
            <div>
                <div>
                    <div className={styles.subtitles}>
                        <h2>Session en cours</h2>
                        <h3>Description de la session</h3>
                    </div>
                    <div className={styles.devicesContainer}>
                        {objectKeys(allDevices)
                            .filter((deviceType) => deviceType !== 'mediator')
                            .map((deviceType) => {
                                const deviceContent = allDevices[deviceType];
                                return (
                                    <div
                                        key={deviceType}
                                        className={`${styles.device} `}
                                    >
                                        <div
                                            className={styles.tablet}
                                            style={{ borderColor: deviceType }}
                                        >
                                            <p>
                                                {deviceContent.isConnected
                                                    ? '✅'
                                                    : '❌'}
                                            </p>
                                        </div>
                                        <span>{deviceContent.label}</span>
                                        <StatusIndicator
                                            status={deviceContent.isConnected}
                                        />
                                        <button
                                            className={styles.button}
                                            onClick={() =>
                                                handleReconnection(deviceType)
                                            }
                                        >
                                            Resynchroniser
                                        </button>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.mediatorActions}>
                    <h1>Actions Générales</h1>
                    {gameStatus !== GAMESTATUS.WELCOME_SCREEN && (
                        <h2>
                            En cours : {getTextFromCurrentGameStatus(gameStatus)}
                        </h2>
                    )}

                    {/* ****************   BUTTONS COMMONS TO ALL GAMES   ************** */}
                    {/* resetGame, initGame, next/prev overlay, display score page, prevent message and unlock Code  */}
                    <MediatorAction
                        HandleFunctions={handleReset}
                        title={resetGame.btnTitle}
                        description={resetGame.description}
                        displayingCondition={true}
                    />
                    <MediatorAction
                        HandleFunctions={() =>
                            handleGoToGameStatus(GAMESTATUS.INTRODUCTION)
                        }
                        title='Jouer'
                        description='Démarrer le jeu'
                        displayingCondition={
                            gameStatus === GAMESTATUS.WELCOME_SCREEN
                        }
                    />
                    <MediatorAction
                        HandleFunctions={() => {
                            handleClick(btnActions?.nextMessage?.nameFunctions[0]);
                        }}
                        title={btnActions?.nextMessage?.btnTitle || ''}
                        description={btnActions?.nextMessage?.description}
                        displayingCondition={
                            isIntroductionGame || isPreventionMessage
                        }
                    />
                    <MediatorAction
                        HandleFunctions={() => {
                            handleClick(btnActions?.prevMessage?.nameFunctions[0]);
                        }}
                        title={btnActions?.prevMessage?.btnTitle || ''}
                        description={btnActions?.prevMessage?.description}
                        displayingCondition={
                            (isIntroductionGame || isPreventionMessage) &&
                            currentOverlayIndex > 0
                        }
                    />
                    <MediatorAction
                        HandleFunctions={() => {
                            handleClick(displayScore?.nameFunctions);
                        }}
                        title={displayScore.btnTitle}
                        description={displayScore.description}
                        displayingCondition={
                            gameStatus !== GAMESTATUS.WELCOME_SCREEN &&
                            gameStatus !== GAMESTATUS.INTRODUCTION &&
                            gameStatus !== GAMESTATUS.FINISHED &&
                            !isScorePage
                        }
                    />
                    <MediatorAction
                        HandleFunctions={() => {
                            handleClick(displayPreventMessage?.nameFunction);
                        }}
                        title={displayPreventMessage.btnTitle}
                        description={displayPreventMessage.description}
                        displayingCondition={
                            gameStatus !== GAMESTATUS.WELCOME_SCREEN &&
                            gameStatus !== GAMESTATUS.INTRODUCTION &&
                            !isPreventionMessage &&
                            gameStatus !== GAMESTATUS.FINISHED
                        }
                    />
                    <MediatorAction
                        HandleFunctions={() => {
                            handleClick(displayUnlockCode?.nameFunctions);
                        }}
                        title={displayUnlockCode.btnTitle}
                        description={displayUnlockCode.description}
                        displayingCondition={
                            gameStatus !== GAMESTATUS.WELCOME_SCREEN &&
                            gameStatus !== GAMESTATUS.INTRODUCTION &&
                            gameStatus !== GAMESTATUS.FINISHED &&
                            !isUnlockPage
                        }
                    />
                    <MediatorAction
                        HandleFunctions={() => {
                            handleClick(btnActions?.nextGame?.nameFunctions[0]);
                        }}
                        title={btnActions?.nextGame?.btnTitle || ''}
                        description={btnActions?.nextGame?.description}
                        displayingCondition={isUnlockPage}
                    />

                    {/* ****************  ACTIONS BUTTONS FOR CURRENT GAME   ************** */}
                    <div className={styles.separator} />
                    <h1>Accès jeux</h1>
                    {gameStatus === GAMESTATUS.GAME4ACTION && (
                        <RenderComponentGameAction4 />
                    )}

                    {isFirstsThreeGameAction && <RenderComponentGamesActions />}

                    {/* ****************  BUTTONS TO ACCESS GAMES ANYTIME  ************** */}
                    {gamesAccess.map((action, index) => (
                        <MediatorAction
                            key={index}
                            HandleFunctions={() => {
                                handleGoToGameStatus(
                                    action.accessGameStatus as GameStatus
                                );
                            }}
                            title={action.btnTitle}
                            description={action.description}
                            displayingCondition={true}
                        />
                    ))}

                    {/* **************** DESACTIVATE / ACTIVATE TABLETS ************** */}
                    <div className={styles.separator}></div>
                    <h1>Désactivation / Activation Tablettes</h1>
                    <ToggleTabletStatus />
                </div>
            </div>
        </div>
    );
};

export default MediatorScreen;

import { SideBannerError, SideBannerSuccess } from '@/assets/images/tv';
import styles from '@/features/devices/tv/components/overlay/overlay.module.scss';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { useOverlayStore } from '@/store/overlayStore';

const Banner: React.FC = () => {
    const overlayType = useOverlayStore.use.overlayType();
    const { currentTitle } = useCurrentOverlay();

    return (
        <div className={styles.bannerContainer}>
            <img
                className={styles.knotLeft}
                src={
                    overlayType === 'success' ? SideBannerSuccess : SideBannerError
                }
                alt={'noeud bannière'}
            />
            <div className={`${styles[`banner-${overlayType}`]} `}>
                <h1>{currentTitle}</h1>
            </div>
            <img
                className={styles.knotRight}
                src={
                    overlayType === 'success' ? SideBannerSuccess : SideBannerError
                }
                alt={'noeud bannière'}
            />
        </div>
    );
};

export default Banner;

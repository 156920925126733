import createSelectors from '@/store/selectors';
import { deepCopy, INITIAL_GAME_STORE } from '@/sessionInfo/sessionInfo';
import type { GameResult } from '@/types/games/types';
import { type GameStatus } from '@/types/global/types';
import { create } from 'zustand';

const useGameStoreBase = create(() => deepCopy(INITIAL_GAME_STORE));

export const useGameStore = createSelectors(useGameStoreBase);

export const setGameStatus = (gameStatus: GameStatus) => {
    useGameStoreBase.setState({
        gameStatus,
    });
};

export const setIsAudioPlaying = (isAudioPlaying: boolean) => {
    useGameStoreBase.setState({
        isAudioPlaying,
    });
};

export const setGameResult = (gameResult: GameResult) => {
    useGameStoreBase.setState({
        gameResult,
    });
};

export const setIsThresholdReached = (isThresholdReached: boolean) => {
    useGameStoreBase.setState({
        isThresholdReached,
    });
};

export const resetGame = () => {
    useGameStoreBase.setState(deepCopy(INITIAL_GAME_STORE));
};

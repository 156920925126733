import globalDatas from '@/data/global.json';
import overlayContent from '@/data/overlayDeviceContent.json';
import { validateGame1Action } from '@/services/games/game1/gameOneHandleFunctions';
import { validateGame2Action } from '@/services/games/game2/gameTwoHandleFunctions';
import { validateGame3Action } from '@/services/games/game3/gameThreeHandleFunctions';
import {
    emitSocketEvent,
    findGoodCombination,
    getItemfromId,
    getNextGameStatus,
    itemInGoodCombination,
    mainItemKeyConfig,
    objectKeys,
    tabletStatusConfigAtValidation,
    tabletStatusGameConfig,
} from '@/services/global/globalUtils';
import { datasGameStatus } from '@/services/mediator/mediatorUtils';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import {
    MAX_ITEMS_FOUND_GAME_1,
    MAX_ITEMS_FOUND_GAME_2,
    MAX_ITEMS_FOUND_GAME_3,
    MAX_TRY_COUNT,
} from '@/sessionInfo/index';
import type { Character } from '@/types/games/game3/types';
import {
    GAMESTATUS,
    OverlayType,
    type DeviceColor,
    type DeviceColorWithTvAndMediator,
    type GameStatus,
    type GameStatusActionWithoutGame4,
    type GoodCombination,
    type GoodCombinationKey,
    type Item,
    type ItemsSelected,
    type OverlayDescription,
    type TryCountInfo,
} from '@/types/global/types';

export const handleReset = () => {
    emitSocketEvent('send_reset', {});
};

export const handleSendGameStatus = (gameStatus: GameStatus) => {
    emitSocketEvent('send_game_status', { status: gameStatus });
};

export const handleUpdateDeviceActivatedStatus = (
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
) => {
    emitSocketEvent('send_device_activated_status', {
        deviceColor,
        status,
    });
};

export const handleSendIsAudioPlaying = (isAudioPlaying: boolean) => {
    const responsesSocket = {
        isAudioPlaying: isAudioPlaying,
    };
    emitSocketEvent('send_is_audio_playing', responsesSocket);
};

export const handleTabletStatus = (deviceActivationStatus: {
    [color in DeviceColor]: boolean;
}) => {
    Object.entries(deviceActivationStatus).forEach(([color, status]) => {
        handleUpdateDeviceActivatedStatus(color as DeviceColor, status);
    });
};

export const handleGoToGameStatus = (gameStatus: GameStatus) => {
    handleSendGameStatus(gameStatus);
    handleTabletStatus(tabletStatusGameConfig[gameStatus]);

    const isGameAction =
        gameStatus === GAMESTATUS.GAME1ACTION ||
        gameStatus === GAMESTATUS.GAME2ACTION ||
        gameStatus === GAMESTATUS.GAME3ACTION ||
        gameStatus === GAMESTATUS.GAME4ACTION;

    if (isGameAction) {
        handleUpdateOverlayDescriptions([], OverlayType.NEUTRAL);
    } else {
        handleUpdateOverlayDescriptions(
            overlayContent[gameStatus].tv,
            OverlayType.NEUTRAL
        );
    }
};

export const handleUpdateOverlayIndex = (overlayIndex: number) => {
    emitSocketEvent('send_update_overlay_index', { overlayIndex });
};

export const handleUpdateOverlayType = (overlayType: OverlayType) => {
    emitSocketEvent('send_update_overlay_type', { overlayType });
};

export const handleUpdateOverlayDescriptions = (
    overlayDescriptions: OverlayDescription[],
    overlayType: OverlayType
) => {
    emitSocketEvent('send_update_overlay_descriptions', {
        overlayDescriptions,
        overlayType,
    });
};

export const handleNextOrPreviousOverlayDescription = (
    action: 'next' | 'previous'
) => {
    const nextGameStatus = getNextGameStatus();
    const { overlayDescriptions, currentOverlayIndex } = useOverlayStore.getState();

    if (action === 'next') {
        const isLastOverlayMessage =
            overlayDescriptions &&
            currentOverlayIndex === overlayDescriptions.length - 1;

        if (isLastOverlayMessage) {
            handleGoToGameStatus(nextGameStatus);
        } else {
            handleUpdateOverlayIndex(currentOverlayIndex + 1);
        }
    } else if (action === 'previous') {
        const isFirstMessage = overlayDescriptions && currentOverlayIndex === 0;
        if (!isFirstMessage) handleUpdateOverlayIndex(currentOverlayIndex - 1);
    }
};

export const handleItemSelected = (
    item: Item,
    itemKey: keyof ItemsSelected,
    gameStatus: GameStatusActionWithoutGame4
) => {
    emitSocketEvent('send_update_item_selected', {
        item,
        itemKey,
        gameStatus,
    });
};

export const handleItemsFound = (
    item: Item,
    gameStatus: GameStatusActionWithoutGame4
) => {
    emitSocketEvent('send_update_item_found', {
        item,
        gameStatus,
    });
};

export const handleValidateSelectedItems = (
    itemsSelected: ItemsSelected,
    tryCount: TryCountInfo[]
) => {
    const gameStatus = useGameStore.getState()
        .gameStatus as GameStatusActionWithoutGame4;

    let overlayDatas;
    const mainItemKey = mainItemKeyConfig[gameStatus];

    switch (gameStatus) {
        case GAMESTATUS.GAME1ACTION:
            overlayDatas = validateGame1Action(itemsSelected);
            break;
        case GAMESTATUS.GAME2ACTION:
            overlayDatas = validateGame2Action(itemsSelected);
            break;
        case GAMESTATUS.GAME3ACTION:
            overlayDatas = validateGame3Action(itemsSelected);
            break;
    }
    if (!overlayDatas) return;

    let overlayDatasMessage = overlayDatas.message as OverlayDescription[];

    if (overlayDatas.type === 'error') {
        const mainItemSelected = itemsSelected[mainItemKey] as Item | Character;
        const mainItemSelectedId = mainItemSelected.id;
        handleUpdateTryCount(mainItemSelectedId, gameStatus);
        handleResetValidation(gameStatus);

        if (itemInGoodCombination(gameStatus, mainItemKey, mainItemSelected)) {
            const itemsSelectedTryCount = tryCount.find(
                (item) => item.mainItemId === mainItemSelectedId
            )?.count;

            // If the max error is reached
            if (
                itemsSelectedTryCount &&
                itemsSelectedTryCount === MAX_TRY_COUNT - 1
            ) {
                // need to reset selected items before sending the good combination in itemsSelected
                handleSendGoodCombination(
                    findGoodCombination(
                        gameStatus,
                        mainItemKey,
                        mainItemSelectedId
                    ),
                    gameStatus
                );
                overlayDatasMessage =
                    globalDatas.thresholdErrorMessage as OverlayDescription[];
                handleUpdateIsThresholdReached(true);
            }
        }
    }

    handleUpdateOverlayDescriptions(overlayDatasMessage, overlayDatas.type);

    handleTabletStatus(tabletStatusConfigAtValidation[gameStatus]);
};

export const handleValidation = (
    itemsSelected: ItemsSelected,
    itemsFound: Item[],
    tryCount: TryCountInfo[]
) => {
    const gameStatus = useGameStore.getState()
        .gameStatus as GameStatusActionWithoutGame4;

    const overlayType = useOverlayStore.getState().overlayType;

    const itemsFoundLength = itemsFound.filter(
        (element) => element !== null
    ).length;
    const nextGameStatus = getNextGameStatus();
    const mainItemKey = mainItemKeyConfig[gameStatus];
    const mainItemSelected = itemsSelected[mainItemKey] as Item;
    const itemsSelectedTryCount = tryCount?.find(
        (item) => item.mainItemId === mainItemSelected.id
    )?.count;

    if (!itemInGoodCombination(gameStatus, mainItemKey, mainItemSelected)) {
        handleTabletStatus(
            tabletStatusGameConfig[
                gameStatus as keyof typeof tabletStatusGameConfig
            ]
        );
        handleUpdateOverlayDescriptions([], OverlayType.NEUTRAL);
        return;
    }

    const handleGameAction = (
        itemsKey: keyof ItemsSelected,
        maxItemsFound: number
    ): boolean => {
        // If it is the last item to find
        if (itemsFoundLength === maxItemsFound - 1) {
            // If the item selected was the right item or if the max error is reached
            if (
                overlayType === 'success' ||
                itemsSelectedTryCount === MAX_TRY_COUNT
            ) {
                handleItemsFound(itemsSelected[mainItemKey] as Item, gameStatus);
                handleResetValidation(gameStatus);
                handleUpdateIsThresholdReached(false);
                handleGoToGameStatus(nextGameStatus);
                return true;
            }
        }
        if (itemsSelectedTryCount) {
            if (itemsSelectedTryCount === MAX_TRY_COUNT) {
                handleUpdateIsThresholdReached(false);
                handleItemsFound(itemsSelected[mainItemKey] as Item, gameStatus);
                handleResetValidation(gameStatus);
            } else if (overlayType === 'success') {
                handleItemsFound(itemsSelected[itemsKey] as Item, gameStatus);
                handleResetValidation(gameStatus);
            }
        } else {
            handleItemsFound(itemsSelected[itemsKey] as Item, gameStatus);
            handleResetValidation(gameStatus);
        }
        return false;
    };

    let lastItemFounded;

    switch (gameStatus) {
        case GAMESTATUS.GAME1ACTION:
            lastItemFounded = handleGameAction('products', MAX_ITEMS_FOUND_GAME_1);
            break;
        case GAMESTATUS.GAME2ACTION:
            lastItemFounded = handleGameAction('objects', MAX_ITEMS_FOUND_GAME_2);
            break;
        case GAMESTATUS.GAME3ACTION:
            lastItemFounded = handleGameAction(
                'characters',
                MAX_ITEMS_FOUND_GAME_3
            );
            break;
    }

    if (lastItemFounded) return;

    handleTabletStatus(tabletStatusGameConfig[gameStatus]);
    handleUpdateOverlayDescriptions([], OverlayType.NEUTRAL);
};

export const handleResetValidation = (gameStatus: GameStatusActionWithoutGame4) => {
    emitSocketEvent('send_reset_items_selected', {
        gameStatus,
    });
};

export const handleUpdateTryCount = (
    id: number,
    gameStatus: GameStatusActionWithoutGame4
) => {
    emitSocketEvent('send_update_try_count', {
        id,
        gameStatus,
    });
};

export const handleSendGoodCombination = (
    goodCombination: GoodCombination,
    gameStatus: GameStatusActionWithoutGame4
) => {
    const datasFromGameStatus = datasGameStatus(gameStatus);

    objectKeys(datasFromGameStatus).forEach((itemKey) => {
        const currentGoodCombinationIds =
            goodCombination[`${itemKey}Id` as GoodCombinationKey];

        if (typeof currentGoodCombinationIds === 'number') {
            handleItemSelected(
                getItemfromId(
                    currentGoodCombinationIds,
                    itemKey as keyof ItemsSelected,
                    gameStatus
                )!,
                itemKey as keyof ItemsSelected,
                gameStatus
            );
            return;
        }

        currentGoodCombinationIds?.forEach((id) => {
            const itemFromId = getItemfromId(
                id,
                itemKey as keyof ItemsSelected,
                gameStatus
            );
            handleItemSelected(
                itemFromId,
                itemKey as keyof ItemsSelected,
                gameStatus
            );
            if (!itemFromId) return;
        });
    });
};

export const handleUpdateIsThresholdReached = (isThresholdReached: boolean) => {
    emitSocketEvent('send_is_threshold_reached', {
        isThresholdReached,
    });
};

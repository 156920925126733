import ActionButton from '@/features/devices/tablets/components/actionButton/ActionButton';
import styles from '@/features/devices/tablets/components/card/cardContrainerVital.module.scss';
import VitalCard from '@/features/devices/tv/components/card/VitalCard';
import dataGlobal from '@/data/global.json';
import {
    getVitalCardsByEnvironment,
    isObjectEmpty,
} from '@/services/global/globalUtils';
import { type GameStatusActionWithoutGame4 } from '@/types/global/types';
import { useItemsSelected } from '@/hooks/useItemsSelected';
import { useGameStore } from '@/store/gameStore';

const CardContainerVital: React.FC = () => {
    const currentGameStatus = useGameStore.use.gameStatus();

    const itemsSelected = useItemsSelected(
        currentGameStatus as GameStatusActionWithoutGame4
    );

    const isSelectionFullFilled =
        !isObjectEmpty(itemsSelected.characters) &&
        !isObjectEmpty(itemsSelected.diseases) &&
        !isObjectEmpty(itemsSelected.environments);

    const vitalsCardByEnvironment = getVitalCardsByEnvironment(
        itemsSelected.environments.id,
        currentGameStatus
    );

    return (
        <div className={styles.cardWrapperVital}>
            <div>
                {vitalsCardByEnvironment.map((character) => {
                    const isSelected = itemsSelected.characters.id === character.id;

                    return (
                        <VitalCard
                            key={character.id}
                            character={character}
                            isSelected={isSelected}
                        />
                    );
                })}
            </div>
            {isSelectionFullFilled && (
                <ActionButton
                    title={dataGlobal.buttons.validate}
                    action='handleValidationSelection'
                    color={'green'}
                    style={{ position: 'absolute', bottom: '4%', zIndex: 1 }}
                />
            )}
        </div>
    );
};

export default CardContainerVital;

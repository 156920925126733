import goodCombinationsData from '@/data/goodCombinations.json';
import {
    handleGame4Validation,
    handleResetItems4,
    handleUpdateSituationIndexGame4,
    validateGame4Action,
} from '@/services/games/game4/gameFourHandleFunctions';
import { useGame4Store } from '@/store/games/game4Store';

export const getCurrentGoodCombination = () => {
    const currentSituationIndex = useGame4Store.getState().currentSituationIndex;
    return goodCombinationsData.GAME4ACTION[currentSituationIndex];
};

export const goToPreviousSituation = () => {
    const currentSituationIndex = useGame4Store.getState().currentSituationIndex;
    handleResetItems4();
    handleUpdateSituationIndexGame4(currentSituationIndex - 1);
};

export const handleGoodCombination = () => {
    const currentSituationIndex = useGame4Store.getState().currentSituationIndex;
    const currentGoodCombination = getCurrentGoodCombination();

    validateGame4Action(
        {
            blue: currentGoodCombination,
            green: currentGoodCombination,
            orange: currentGoodCombination,
            red: currentGoodCombination,
        },
        currentSituationIndex
    );
};

export const skipOverlayMessage = () => {
    const currentSituationIndex = useGame4Store.getState().currentSituationIndex;
    handleGame4Validation(currentSituationIndex);
};

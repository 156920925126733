export const MAX_PRODUCTS_LENGTH = 1;
export const MAX_TYPES_LENGTH = 2;
export const MAX_RISKS_LENGTH = 3;
export const MAX_ITEMS_FOUND_GAME_1 = 4;

export const MAX_ITEMS_FOUND_GAME_2 = 3;

export const MAX_ITEMS_FOUND_GAME_3 = 4;
export const MAX_TRY_COUNT = 3;
export const NUMBER_OF_BUBBLES_TV = 29;
export const NUMBER_OF_BUBBLES_TABLET = 10;
export const MAX_CODE_LENGTH = 6;

export const MAX_OF_DEVICES_IN_A_ROOM = 6;

export * from './sessionInfo';

import { PadClick } from '@/assets/sounds/soundsEffect';
import styles from '@/features/devices/tablets/orange/keypad/keypad.module.scss';
import { playSound } from '@/services/global/globalUtils';
import { motion } from 'framer-motion';
import { useCallback, type Dispatch, type SetStateAction } from 'react';

interface KeyPadProps {
    setCode: Dispatch<SetStateAction<string>>;
}

const KEYPAD_LAYOUT = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '', '0', 'x'];

const Keypad: React.FC<KeyPadProps> = ({ setCode }) => {
    const onDigitClick = useCallback(
        (digit: string) => {
            if (digit === '') return;
            if (digit === 'x') {
                setCode((old) => old.slice(0, -1));
            } else {
                setCode((old) => old + digit);
            }
        },
        [setCode]
    );

    return (
        <div className={styles.keypadContainer}>
            {KEYPAD_LAYOUT.map((digit) => {
                return (
                    <motion.button
                        key={digit}
                        whileTap={{
                            scale: 0.8,
                            background:
                                'linear-gradient(180deg, #C08B4C 0%, #B88952 100%)',
                        }}
                        onClick={() => {
                            onDigitClick(digit);
                            playSound(PadClick);
                        }}
                    >
                        <div />
                        <span>{digit}</span>
                    </motion.button>
                );
            })}
        </div>
    );
};

export default Keypad;

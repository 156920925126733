import styles from '@/features/reusablecomponents/global/waitingPlayers/createRoom.module.scss';
import SocketService from '@/services/SocketService';
import { setRoomName } from '@/store/roomStore';
import { useState } from 'react';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';

interface CreateRoomProps {
    setRoom: (roomName: string) => void;
    color: DeviceColorWithTvAndMediator;
}

const CreateRoom: React.FC<CreateRoomProps> = ({ setRoom, color }) => {
    const socket = SocketService.getInstance().getSocket();
    const [sessionRoomName, setSessionRoomName] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSessionRoomName(event.target.value);
    };

    const handleConnectToSocketRoom = () => {
        setRoom(sessionRoomName);
        setRoomName(sessionRoomName);

        socket.emit('send_join_room', { roomName: sessionRoomName, device: color });

        if (color !== 'mediator') {
            socket.emit('send_start_game', { roomName: sessionRoomName, color });
        }
    };

    return (
        <div className={styles.container}>
            <div>
                <input
                    type='text'
                    value={sessionRoomName}
                    placeholder={'Code PIN'}
                    onChange={handleInputChange}
                />
                <button onClick={handleConnectToSocketRoom}>Valider</button>
            </div>
        </div>
    );
};

export default CreateRoom;

import CloudsSmallBackgroundBlue from '/images/tablets/clouds/cloudsSmallBackgroundBlue.png?url';
import CloudsSmallBackgroundGreen from '/images/tablets/clouds/cloudsSmallBackgroundGreen.png?url';
import CloudsSmallBackgroundOrange from '/images/tablets/clouds/cloudsSmallBackgroundOrange.png?url';
import CloudsSmallBackgroundRed from '/images/tablets/clouds/cloudsSmallBackgroundRed.png?url';

import CloudsMediumBackgroundBlue from '/images/tablets/clouds/cloudsMediumBackgroundBlue.png?url';
import CloudsMediumBackgroundGreen from '/images/tablets/clouds/cloudsMediumBackgroundGreen.png?url';
import CloudsMediumBackgroundOrange from '/images/tablets/clouds/cloudsMediumBackgroundOrange.png?url';
import CloudsMediumBackgroundRed from '/images/tablets/clouds/cloudsMediumBackgroundRed.png?url';

import CloudsBigBackgroundBlue from '/images/tablets/clouds/cloudsBigBackgroundBlue.png?url';
import CloudsBigBackgroundGreen from '/images/tablets/clouds/cloudsBigBackgroundGreen.png?url';
import CloudsBigBackgroundOrange from '/images/tablets/clouds/cloudsBigBackgroundOrange.png?url';
import CloudsBigBackgroundRed from '/images/tablets/clouds/cloudsBigBackgroundRed.png?url';

import CloudsBottomBackgroundOrange from '/images/tablets/clouds/cloudsBottomOrange.png?url';
import CloudsTopBackgroundOrange from '/images/tablets/clouds/cloudsBigTopOrange.png?url';
import CloudsSmallTopBackgroundOrange from '/images/tablets/clouds/cloudsSmallTopOrange.png?url';

import CloudsBottomBackgroundRed from '/images/tablets/clouds/cloudsBottomRed.png?url';
import CloudsTopBackgroundRed from '/images/tablets/clouds/cloudsBigTopRed.png?url';
import CloudsSmallTopBackgroundRed from '/images/tablets/clouds/cloudsSmallTopRed.png?url';

import CloudsBottomBackgroundGreen from '/images/tablets/clouds/cloudsBottomGreen.png?url';
import CloudsTopBackgroundGreen from '/images/tablets/clouds/cloudsBigTopGreen.png?url';
import CloudsSmallTopBackgroundGreen from '/images/tablets/clouds/cloudsSmallTopGreen.png?url';

import CloudsBottomBackgroundBlue from '/images/tablets/clouds/cloudsBottomBlue.png?url';
import CloudsTopBackgroundBlue from '/images/tablets/clouds/cloudsBigTopBlue.png?url';
import CloudsSmallTopBackgroundBlue from '/images/tablets/clouds/cloudsSmallTopBlue.png?url';

export const cloudsSmallBackground = {
    blue: CloudsSmallBackgroundBlue,
    green: CloudsSmallBackgroundGreen,
    orange: CloudsSmallBackgroundOrange,
    red: CloudsSmallBackgroundRed,
};

export const cloudsMediumBackground = {
    blue: CloudsMediumBackgroundBlue,
    green: CloudsMediumBackgroundGreen,
    orange: CloudsMediumBackgroundOrange,
    red: CloudsMediumBackgroundRed,
};

export const cloudsBigBackground = {
    blue: CloudsBigBackgroundBlue,
    green: CloudsBigBackgroundGreen,
    orange: CloudsBigBackgroundOrange,
    red: CloudsBigBackgroundRed,
};

export const cloudsBottomBackground = {
    orange: CloudsBottomBackgroundOrange,
    red: CloudsBottomBackgroundRed,
    green: CloudsBottomBackgroundGreen,
    blue: CloudsBottomBackgroundBlue,
};

export const cloudsTopBackground = {
    orange: {
        small: CloudsSmallTopBackgroundOrange,
        big: CloudsTopBackgroundOrange,
    },
    red: {
        small: CloudsSmallTopBackgroundRed,
        big: CloudsTopBackgroundRed,
    },
    green: {
        small: CloudsSmallTopBackgroundGreen,
        big: CloudsTopBackgroundGreen,
    },
    blue: {
        small: CloudsSmallTopBackgroundBlue,
        big: CloudsTopBackgroundBlue,
    },
};

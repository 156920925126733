import { type Game1Store } from '@/types/games/game1/types';
import { type Game2Store } from '@/types/games/game2/types';
import { type Character, type Game3Store } from '@/types/games/game3/types';
import { type Game4Store } from '@/types/games/game4/types';
import {
    type DeviceStore,
    type GameStore,
    type OverlayStore,
} from '@/types/games/types';
import {
    type DeviceColorWithTvAndMediator,
    type GameStatusActionWithoutGame4,
    type Item,
    type ItemsSelected,
    type Nullable,
    type OverlayDescription,
    type OverlayType,
    type Product,
} from '@/types/global/types';
import type { RoomInfo, SessionInfo } from '@/types/sessionInfo/types';
import { type ServerEvents } from '@/types/socket/types';
import {
    MAX_ITEMS_FOUND_GAME_1,
    MAX_ITEMS_FOUND_GAME_2,
    MAX_ITEMS_FOUND_GAME_3,
    MAX_OF_DEVICES_IN_A_ROOM,
    MAX_RISKS_LENGTH,
    MAX_TYPES_LENGTH,
} from './index';

export const deepCopy = <T>(obj: T): T => {
    return JSON.parse(JSON.stringify(obj));
};

export function storeSocketInfo(
    session: SessionInfo,
    data: { roomName: string; device: DeviceColorWithTvAndMediator },
    socketId: string
): SessionInfo {
    session[data.roomName].sockets.set(data.device, socketId);

    return session;
}

function getUnfilledRooms(
    connectedSockets: Array<{ id: string; room: string }>,
    storedRoomNames: Array<string>
) {
    const socketsIdsByRooms = connectedSockets.reduce(
        (acc, { id, room }) => {
            acc[room] = acc[room] ?? [];
            acc[room].push(id);
            return acc;
        },
        {} as Record<string, Array<string>>
    );

    //Add all the the empty room
    for (const roomName of storedRoomNames) {
        if (!socketsIdsByRooms[roomName]) {
            socketsIdsByRooms[roomName] = [];
        }
    }

    return Object.entries(socketsIdsByRooms).filter(([, ids]) => {
        return ids.length < MAX_OF_DEVICES_IN_A_ROOM;
    });
}

export function getDisconnectedSocketRoomsWithColor(
    session: SessionInfo,
    connectedSockets: Array<{ id: string; room: string }>
): Nullable<[string, DeviceColorWithTvAndMediator]> {
    const roomsWithMissingSockets = getUnfilledRooms(
        connectedSockets,
        Object.keys(session)
    );

    let missingSocketRoomsWithColor: Nullable<
        [string, DeviceColorWithTvAndMediator]
    > = null;

    for (const [room, ids] of roomsWithMissingSockets) {
        let disconnectedSocketColor = null;
        session[room].sockets.forEach((socketId: string | null, color: any) => {
            if (socketId != null && !ids.includes(socketId)) {
                disconnectedSocketColor = color;
            }
        });

        if (!disconnectedSocketColor) continue;

        missingSocketRoomsWithColor = [room, disconnectedSocketColor];
    }

    return missingSocketRoomsWithColor;
}


export function createRoom(roomName: string): SessionInfo {
    return {
        [roomName]: { sockets: new Map(), ...initRoom() },
    };
}

export function deleteEmptyRoom(sessionInfo: SessionInfo) {
    Object.entries(sessionInfo).forEach(([roomName, { sockets }]) => {
        const emptyRoom =
            Array.from(sockets).filter(([, status]) => status !== null).length ===
            0;

        if (emptyRoom) delete sessionInfo[roomName];
    });
}

export function initRoom(): Omit<RoomInfo, 'sockets'> {
    return deepCopy({
        deviceStore: INITIAL_DEVICE_STORE,
        overlayStore: INITAL_OVERLAY_STORE,
        gameStore: INITIAL_GAME_STORE,
        game1Store: INITIAL_GAME1_STORE,
        game2Store: INITIAL_GAME2_STORE,
        game3Store: INITIAL_GAME3_STORE,
        game4Store: INITIAL_GAME4_STORE,
    });
}

export const INITIAL_DEVICE_STORE: DeviceStore = {
    deviceStatus: {
        red: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette rouge',
        },
        blue: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette bleue',
        },
        green: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette verte',
        },
        orange: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette orange',
        },
        tv: {
            isConnected: false,
            isActivated: true,
            label: 'TV',
        },
        mediator: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette Médiateur',
        },
    },
};

export const INITAL_OVERLAY_STORE: OverlayStore = {
    currentOverlayIndex: 0,
    overlayDescriptions: [] as OverlayDescription[],
    overlayType: 'neutral' as OverlayType.NEUTRAL,
};

export const INITIAL_GAME1_STORE: Game1Store = {
    itemsSelected: {
        products: {} as Product,
        risks: Array(MAX_RISKS_LENGTH).fill(null),
        types: Array(MAX_TYPES_LENGTH).fill(null),
    },
    itemsFound: Array(MAX_ITEMS_FOUND_GAME_1).fill(null),
    tryCount: [],
};

export const INITIAL_GAME2_STORE: Game2Store = {
    itemsSelected: {
        objects: {} as Product,
        pathways: {} as Item,
    },
    itemsFound: Array(MAX_ITEMS_FOUND_GAME_2).fill(null),
    tryCount: [],
};

export const INITIAL_GAME3_STORE: Game3Store = {
    itemsSelected: {
        characters: {} as Character,
        diseases: {} as Item,
        environments: {} as Item,
    },
    itemsFound: Array(MAX_ITEMS_FOUND_GAME_3).fill(null),
    tryCount: [],
};

export const INITIAL_GAME4_STORE: Game4Store = {
    currentSituationIndex: 0,
    playersAnswer: {
        orange: null,
        red: null,
        green: null,
        blue: null,
    },
};

export const INITIAL_GAME_STORE: GameStore = {
    gameStatus: 'WELCOME_SCREEN',
    isAudioPlaying: false,
    gameResult: '',
    isThresholdReached: false,
};

export const mapGameStatusToStore = (gameStatus: GameStatusActionWithoutGame4) => {
    switch (gameStatus) {
        case 'GAME1ACTION':
            return 'game1Store';
        case 'GAME2ACTION':
            return 'game2Store';
        case 'GAME3ACTION':
            return 'game3Store';
    }
};

export const updateTryCount = (
    roomInfo: RoomInfo,
    id: number,
    gameStatus: GameStatusActionWithoutGame4
) => {
    const storeToUpdate = mapGameStatusToStore(gameStatus);
    const currentTryCount = roomInfo[storeToUpdate].tryCount;
    const existingObject = currentTryCount.find((item: { mainItemId: number; }) => item.mainItemId === id);

    if (existingObject) {
        existingObject.count += 1;
    } else {
        currentTryCount.push({ mainItemId: id, count: 1 });
    }
};

export const updateItemsFound = (
    roomInfo: RoomInfo,
    item: Item,
    gameStatus: GameStatusActionWithoutGame4
) => {
    const storeToUpdate = mapGameStatusToStore(gameStatus);

    const itemsFound = roomInfo[storeToUpdate].itemsFound;
    const firstEmptyIndex = itemsFound.findIndex((item: null) => item == null);

    if (firstEmptyIndex === -1) return;
    itemsFound[firstEmptyIndex] = item;

    roomInfo[storeToUpdate].itemsFound = itemsFound;
};

export const resetRoomInfo = <
    T extends 'itemsSelected' | 'itemsFound' | 'tryCount',
>(
    roomInfo: RoomInfo,
    valueToUpdate: T,
    gameStatus: GameStatusActionWithoutGame4
) => {
    const storeToUpdate = mapGameStatusToStore(gameStatus);

    const initialStoreValues = {
        game1Store: INITIAL_GAME1_STORE,
        game2Store: INITIAL_GAME2_STORE,
        game3Store: INITIAL_GAME3_STORE,
    };

    roomInfo[storeToUpdate][valueToUpdate] = deepCopy(
        initialStoreValues[storeToUpdate][valueToUpdate]
    );
};

export const updateItemsSelected = <
    TItemKey extends keyof ItemsSelected,
    TItem extends ItemsSelected[TItemKey],
>(
    roomInfo: RoomInfo,
    data: {
        item: TItem;
        itemKey: TItemKey;
        gameStatus: GameStatusActionWithoutGame4;
    }
) => {
    const storeToUpdate = mapGameStatusToStore(data.gameStatus);
    const itemsSelected = roomInfo[storeToUpdate].itemsSelected as ItemsSelected;

    const itemToUpdate = itemsSelected[data.itemKey];

    if (Array.isArray(itemToUpdate)) {
        const index = itemToUpdate.findIndex(
            (selectedItem: { id: number; }) => selectedItem?.id === (data.item as Item).id
        );

        if (index !== -1) {
            itemToUpdate[index] = null as unknown as Item;
        } else {
            const firstEmptyIndex = itemToUpdate.findIndex(
                (selectedItem: null) => selectedItem == null
            );

            if (firstEmptyIndex !== -1)
                itemToUpdate[firstEmptyIndex] = data.item as Item;
        }
        itemsSelected[data.itemKey] = itemToUpdate;
        return;
    }

    itemsSelected[data.itemKey] = data.item;
};

import actionDatas from '@/data/actionDatas.json';
import goodCombinations from '@/data/goodCombinations.json';
import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import {
    handleResetValidation,
    handleSendGoodCombination,
    handleTabletStatus,
    handleUpdateOverlayDescriptions,
    handleValidation,
    handleValidateSelectedItems,
} from '@/services/global/globalHandleFunctions';
import {
    handleResetItemsFound,
    handleResetTryCount,
} from '@/services/mediator/mediatorHandleFunctions';
import {
    GAMESTATUS,
    OverlayType,
    type GameStatusActionWithoutGame4,
    type GoodCombination,
} from '@/types/global/types';

import { resetItemsFound } from '@/data/mediator/genericDatas.json';
import { useItemsFound } from '@/hooks/useItemsFound';
import { useItemsSelected } from '@/hooks/useItemsSelected';
import { useTryCount } from '@/hooks/useTryCount';
import { isObjectEmpty } from '@/services/global/globalUtils';
import { actionsGames } from '@/services/mediator/mediatorUtils';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import { useMemo } from 'react';

const RenderComponentGamesActions: React.FC = () => {
    const gameStatus =
        useGameStore.use.gameStatus() as GameStatusActionWithoutGame4;
    const overlayType = useOverlayStore.use.overlayType();

    const isOverlaySuccess = overlayType === 'success';
    const isOverlayError = overlayType === 'error';
    const isOverlay = isOverlaySuccess || isOverlayError;

    const btnActions = actionsGames[gameStatus].actions;

    const itemsSelected = useItemsSelected(gameStatus);
    const itemsFound = useItemsFound(gameStatus);
    const tryCount = useTryCount(gameStatus);

    const atLeastOneItemFound = itemsFound?.some((item) => item !== null) || false;

    const isSelectionGame1FullFilled = useMemo(
        () =>
            !isObjectEmpty(itemsSelected?.products) &&
            itemsSelected?.types.some((item) => item !== null) &&
            itemsSelected?.risks.some((item) => item !== null),
        [itemsSelected]
    );

    const isSelectionGame2FullFilled = useMemo(
        () =>
            !isObjectEmpty(itemsSelected?.objects) &&
            !isObjectEmpty(itemsSelected?.pathways),
        [itemsSelected]
    );

    const isSelectionGame3FullFilled = useMemo(
        () =>
            !isObjectEmpty(itemsSelected?.characters) &&
            !isObjectEmpty(itemsSelected?.diseases) &&
            !isObjectEmpty(itemsSelected?.environments),
        [itemsSelected]
    );

    const isSelectionFullFilled =
        isSelectionGame1FullFilled ||
        isSelectionGame2FullFilled ||
        isSelectionGame3FullFilled;

    const handleClick = (action: string, goodCombination?: GoodCombination) => {
        switch (action) {
            case 'validateSelection':
                handleValidateSelectedItems(itemsSelected, tryCount);
                break;
            case 'passMessage':
                handleValidation(itemsSelected, itemsFound, tryCount);
                break;
            case 'updateOverlayDescriptionsToEmpty':
                handleUpdateOverlayDescriptions([], OverlayType.NEUTRAL);
                break;
            case 'resetItemsFound':
                handleResetItemsFound();
                handleResetTryCount();
                break;
            case 'activateAllTablets':
                handleTabletStatus({
                    blue: true,
                    green: true,
                    orange: true,
                    red: true,
                });
                break;
            case 'activateOrangeTabletOnly':
                handleTabletStatus({
                    blue: false,
                    green: false,
                    orange: true,
                    red: false,
                });
                break;
            case 'activateRedTabletOnly':
                handleTabletStatus({
                    blue: false,
                    green: false,
                    orange: false,
                    red: true,
                });
                break;
            case 'activateGreenTabletOnly':
                handleTabletStatus({
                    blue: false,
                    green: true,
                    orange: false,
                    red: false,
                });
                break;
            case 'selectGoodCombination':
                handleResetValidation(gameStatus);
                goodCombination &&
                    handleSendGoodCombination(goodCombination, gameStatus);
                break;
        }
    };

    const currentGameGoodCombinations = goodCombinations[
        gameStatus
    ] as GoodCombination[];

    const getNameOfMainItemToFind = (goodCombination: GoodCombination) => {
        switch (gameStatus) {
            case GAMESTATUS.GAME1ACTION:
                return actionDatas[gameStatus]['products'].find(
                    (product) => product.id === goodCombination.productsId
                )!.name;
            case GAMESTATUS.GAME2ACTION:
                return actionDatas[gameStatus]['objects'].find(
                    (object) => object.id === goodCombination.objectsId
                )!.name;
            case GAMESTATUS.GAME3ACTION:
                return actionDatas[gameStatus]['characters'].find(
                    (character) => character.id === goodCombination.charactersId
                )!.name;
        }
    };

    const isItemFound = (goodCombination: GoodCombination) => {
        switch (gameStatus) {
            case GAMESTATUS.GAME1ACTION:
                return itemsFound.some(
                    (item) => item && item.id === goodCombination.productsId
                );
            case GAMESTATUS.GAME2ACTION:
                return itemsFound.some(
                    (item) => item && item.id === goodCombination.objectsId
                );
            case GAMESTATUS.GAME3ACTION:
                return itemsFound.some(
                    (item) => item && item.id === goodCombination.charactersId
                );
        }
    };

    const renderMediatorAction = () => {
        return currentGameGoodCombinations.map((goodCombination, index) => {
            if (isItemFound(goodCombination)) {
                return null;
            }
            return (
                !isOverlay && (
                    <MediatorAction
                        key={index}
                        HandleFunctions={() => {
                            btnActions?.selectGoodCombination.nameFunctions?.map(
                                (nameFunction) => {
                                    handleClick(nameFunction, goodCombination);
                                }
                            );
                        }}
                        title={
                            `${
                                btnActions?.selectGoodCombination.btnTitle
                            } ${getNameOfMainItemToFind(goodCombination)}` || ''
                        }
                        description={btnActions?.selectGoodCombination.description}
                        displayingCondition={true}
                        type='secondary'
                    />
                )
            );
        });
    };
    return (
        <>
            {isSelectionFullFilled && !isOverlay && (
                <MediatorAction
                    HandleFunctions={() => {
                        btnActions?.validateSelection.nameFunctions.map(
                            (nameFunction) => {
                                handleClick(nameFunction);
                            }
                        );
                    }}
                    title={btnActions?.validateSelection.btnTitle || ''}
                    description={btnActions?.validateSelection.description}
                    displayingCondition={true}
                    type='secondary'
                />
            )}
            {isOverlay && (
                <MediatorAction
                    HandleFunctions={() => {
                        btnActions?.[
                            isOverlaySuccess ? 'overlaySuccess' : 'overlayError'
                        ].nameFunctions.map((nameFunction) => {
                            handleClick(nameFunction);
                        });
                    }}
                    title={
                        btnActions?.[
                            isOverlaySuccess ? 'overlaySuccess' : 'overlayError'
                        ].btnTitle || ''
                    }
                    description={
                        btnActions?.[
                            isOverlaySuccess ? 'overlaySuccess' : 'overlayError'
                        ].description
                    }
                    displayingCondition={true}
                    type='secondary'
                />
            )}
            <>{renderMediatorAction()}</>
            <MediatorAction
                HandleFunctions={() => {
                    handleClick(resetItemsFound.nameFunction);
                }}
                title={resetItemsFound.btnTitle}
                description={resetItemsFound.description}
                displayingCondition={atLeastOneItemFound}
                type='secondary'
            />
        </>
    );
};
export default RenderComponentGamesActions;

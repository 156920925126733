import goodCombinationsData from '@/data/goodCombinations.json';
import type { Game3ItemsSelected } from '@/types/games/game3/types';
import { GAMESTATUS, type Message, OverlayType } from '@/types/global/types';

export const validateGame3Action = (
    itemsSelected: Game3ItemsSelected
): { message?: Message[]; type: OverlayType } => {
    const goodCombinations = goodCombinationsData[GAMESTATUS.GAME3ACTION];
    const {
        characters: selectedCharacter,
        diseases: selectedDisease,
        environments: selectedEnvironement,
    } = itemsSelected;

    const isValidated = goodCombinations.some(
        (combination) =>
            selectedCharacter.id === combination.charactersId &&
            selectedDisease.id === combination.diseasesId &&
            selectedEnvironement.id === combination.environmentsId
    );

    return {
        message: isValidated
            ? selectedCharacter.successMessage
            : selectedCharacter.errorMessage,
        type: isValidated ? OverlayType.SUCCESS : OverlayType.ERROR,
    };
};

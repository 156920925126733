import { GreenCircle, RedCircle } from '@/assets/images/game4';
import actionData from '@/data/actionDatas.json';
import styles from '@/features/devices/tv/components/game4/game4.module.scss';
import Timer from '@/features/devices/tv/components/timer/Timer';
import AudioComponent from '@/features/reusablecomponents/global/AudioComponent';
import { validateGame4Action } from '@/services/games/game4/gameFourHandleFunctions';
import { useGame4Store } from '@/store/games/game4Store';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { Game4PlayersAnswer } from '@/types/games/game4/types';

const ANIMATION_DURATION = 800;
const NUMBER_OF_PLAYERS = 4;
const TIME_TO_ANSWER = 30;

const useCountTrueFalseValues = (
    playersAnswer: Game4PlayersAnswer,
    value: boolean
) => {
    return useMemo(
        () => Object.values(playersAnswer).filter((v) => v === value).length,
        [value, playersAnswer]
    );
};

const useHaveAllPlayersAgree = (playersAnswer: Game4PlayersAnswer) => {
    return useMemo(
        () =>
            Object.values(playersAnswer).every((value) => value === true) ||
            Object.values(playersAnswer).every((value) => value === false),
        [playersAnswer]
    );
};

const Game4: React.FC = () => {
    const playersAnswer = useGame4Store.use.playersAnswer();
    const currentSituationIndex = useGame4Store.use.currentSituationIndex();

    const currentSituation =
        actionData.GAME4ACTION.situations[currentSituationIndex];

    const [animateTrue, setAnimateTrue] = useState(false);
    const [animateFalse, setAnimateFalse] = useState(false);
    const [showTimer, setShowTimer] = useState(false);

    const numberOfTrue = useCountTrueFalseValues(playersAnswer, true);
    const numberOfFalse = useCountTrueFalseValues(playersAnswer, false);

    const prevNumberOfTrue = useRef(numberOfTrue);
    const prevNumberOfFalse = useRef(numberOfFalse);

    const areAllPlayersAgree = useHaveAllPlayersAgree(playersAnswer);

    useEffect(() => {
        setShowTimer(false);
    }, []);

    useEffect(() => {
        const animate = (
            current: number,
            previous: React.MutableRefObject<number>,
            setAnimate: React.Dispatch<React.SetStateAction<boolean>>
        ) => {
            // If the number of true or false has increased, we light up the circle
            if (current > previous.current) {
                setAnimate(true);
                setTimeout(() => setAnimate(false), ANIMATION_DURATION);
            }
            previous.current = current;
        };

        animate(numberOfTrue, prevNumberOfTrue, setAnimateTrue);
        animate(numberOfFalse, prevNumberOfFalse, setAnimateFalse);
    }, [numberOfTrue, numberOfFalse]);

    useEffect(() => {
        // If not all players have answered, we don't do anything
        if (numberOfTrue + numberOfFalse < NUMBER_OF_PLAYERS) return;

        if (areAllPlayersAgree)
            validateGame4Action(playersAnswer, currentSituationIndex);
        else setShowTimer(true);
    }, [
        currentSituationIndex,
        playersAnswer,
        areAllPlayersAgree,
        numberOfTrue,
        numberOfFalse,
        currentSituation.audio,
    ]);

    return (
        <div className={styles.containerGame4Wrapper}>
            <div className={styles.containerGame4}>
                {currentSituation && (
                    <AudioComponent src={currentSituation.audio} />
                )}
                <p>{currentSituation.text}</p>
            </div>
            <div className={styles.tagContainer}>
                <div className={styles.numberSuccessContainer}>
                    <p>{numberOfTrue}</p>
                    <img
                        src={GreenCircle}
                        alt='Green circle'
                        className={animateTrue ? styles.lightning : undefined}
                    />
                </div>
                <div className={styles.numberErrorsContainer}>
                    <p>{numberOfFalse}</p>
                    <img
                        className={animateFalse ? styles.lightning : undefined}
                        src={RedCircle}
                        alt='Red circle'
                    />
                </div>
            </div>
            {showTimer && (
                <Timer
                    time={TIME_TO_ANSWER}
                    callback={() =>
                        validateGame4Action(playersAnswer, currentSituationIndex)
                    }
                />
            )}
        </div>
    );
};

export default Game4;

import { Placeholder } from '@/assets/images/global';
import global from '@/data/global.json';
import styles from '@/features/devices/tv/components/historical/historical.module.scss';
import { useGameStore } from '@/store/gameStore';
import { useItemsFound } from '@/hooks/useItemsFound';
import { type GameStatusActionWithoutGame4 } from '@/types/global/types';

const Historical: React.FC = () => {
    const currentGameStatus = useGameStore.use.gameStatus();
    const itemsFound = useItemsFound(
        currentGameStatus as GameStatusActionWithoutGame4
    );

    return (
        <div className={styles.historicalContainer}>
            {itemsFound.map((item, index) => {
                return (
                    <div key={index}>
                        <img
                            src={item ? item.image : Placeholder}
                            alt={item ? item.name : global.placeholder.alt}
                        />
                        <p>{item ? item.name : global.historical.waiting}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default Historical;

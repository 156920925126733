import goodCombinationsData from '@/data/goodCombinations.json';
import { datasGameStatus } from '@/services/mediator/mediatorUtils';
import SocketService from '@/services/SocketService';
import { useGameStore } from '@/store/gameStore';
import { useRoomStore } from '@/store/roomStore';
import type { Character } from '@/types/games/game3/types';
import {
    GAMESTATUS,
    type GameStatus,
    type GameStatusActionWithoutGame4,
    type GoodCombination,
    type Item,
    type ItemsSelected,
    type Molecule,
    type TryCountInfo,
} from '@/types/global/types';
import type { ClientEvents } from '@/types/socket/types';

export const isDevelopperMode = import.meta.env.VITE_DEVELOPPER_MODE === 'true';

/**
 * Emits a socket event with the given name and data, with the room name.
 * @param {keyof ClientEvents} eventName - The name of the event.
 * @param {Omit<Parameters<ClientEvents[EventKey]>[number], 'roomName'>} data - The data to send depending on the event.
 */
export function emitSocketEvent<EventKey extends keyof ClientEvents>(
    eventName: EventKey,
    data: Omit<Parameters<ClientEvents[EventKey]>[number], 'roomName'>
) {
    const socket = SocketService.getInstance().getSocket();
    const roomName = useRoomStore.getState().roomName;

    if (!socket) return;
    if (roomName === '') {
        console.info(`Room name was empty calling ${eventName}.`);
        return;
    }
    const params = (data ? [{ ...data, roomName }] : [{ roomName }]) as Parameters<
        ClientEvents[EventKey]
    >;
    socket.emit(eventName, ...params);
}

export function extractDigitFromStatus(status: string): number {
    return Number(status.match(/\d+/)?.[0] ?? 0);
}

// Fonction auxiliaire pour comparer deux tableaux d'IDs
export const arraysEqual = (a: number[], b: number[]): boolean => {
    return a.length === b.length && a.every((val, index) => val === b[index]);
};

/**
 *  config for tablet status on game status
 */
export const tabletStatusGameConfig = {
    [GAMESTATUS.WELCOME_SCREEN]: {
        blue: false,
        green: false,
        orange: true,
        red: false,
    },
    [GAMESTATUS.INTRODUCTION]: {
        blue: false,
        green: false,
        orange: true,
        red: false,
    },
    [GAMESTATUS.GAME1INTRODUCTION]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME1ACTION]: {
        blue: true,
        green: true,
        orange: true,
        red: true,
    },
    [GAMESTATUS.GAME1SCORE]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME1PREVENTION]: {
        blue: false,
        green: false,
        orange: false,
        red: true,
    },
    [GAMESTATUS.GAME1UNLOCK]: {
        blue: false,
        green: true,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME2INTRODUCTION]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME2ACTION]: {
        blue: true,
        green: true,
        orange: true,
        red: true,
    },
    [GAMESTATUS.GAME2SCORE]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME2PREVENTION]: {
        blue: false,
        green: false,
        orange: false,
        red: true,
    },
    [GAMESTATUS.GAME2UNLOCK]: {
        blue: false,
        green: false,
        orange: true,
        red: false,
    },
    [GAMESTATUS.GAME3INTRODUCTION]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME3ACTION]: {
        blue: true,
        green: false,
        orange: true,
        red: false,
    },
    [GAMESTATUS.GAME3SCORE]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME3PREVENTION]: {
        blue: false,
        green: true,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME3UNLOCK]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME4INTRODUCTION]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME4ACTION]: {
        blue: true,
        green: true,
        orange: true,
        red: true,
    },
    [GAMESTATUS.GAME4SCORE]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME4PREVENTION]: {
        blue: false,
        green: false,
        orange: true,
        red: false,
    },
    [GAMESTATUS.GAME4UNLOCK]: {
        blue: false,
        green: true,
        orange: false,
        red: false,
    },
    [GAMESTATUS.FINISHED]: {
        blue: false,
        green: false,
        orange: false,
        red: false,
    },
};

export const tabletStatusConfigAtValidation = {
    [GAMESTATUS.GAME1ACTION]: {
        blue: false,
        green: false,
        orange: false,
        red: true,
    },
    [GAMESTATUS.GAME2ACTION]: {
        blue: false,
        green: true,
        orange: false,
        red: false,
    },
    [GAMESTATUS.GAME3ACTION]: {
        blue: false,
        green: false,
        orange: false,
        red: true,
    },
    [GAMESTATUS.GAME4ACTION]: {
        blue: false,
        green: true,
        orange: false,
        red: false,
    },
};

export const getItemfromId = (
    id: number,
    key: keyof ItemsSelected,
    gameStatus: GameStatus
): Item => {
    const datasFromGameStatus = datasGameStatus(gameStatus);
    const item = datasFromGameStatus[key].find((type: Item) => type.id === id);
    return item!;
};

export const getNextGameStatus = (): GameStatus => {
    const gameStatus = useGameStore.getState().gameStatus;
    const indexCurrentGameStatus = Object.keys(GAMESTATUS).findIndex(
        (status) => status === gameStatus
    );
    return Object.keys(GAMESTATUS)[indexCurrentGameStatus + 1] as GameStatus;
};

export const getMoleculeFromCode = (
    code: string,
    gameStatus: GameStatus
): Molecule => {
    const datasFromGameStatus = datasGameStatus(gameStatus);
    return datasFromGameStatus.molecules.find(
        (molecule: { code: string; }) => molecule.code === code
    )!;
};

export const isObjectEmpty = (obj?: object): boolean => {
    if (!obj) return true;
    return Object.keys(obj).length === 0;
};

export const findGoodCombination = (
    gameStatus: GameStatus,
    mainItemKey: string,
    mainItemId: number
): GoodCombination => {
    const goodCombination = goodCombinationsData[
        gameStatus as keyof typeof goodCombinationsData
    ].find((goodCombination) => {
        return (
            goodCombination[`${mainItemKey}Id` as keyof typeof goodCombination] ===
            mainItemId
        );
    });
    return goodCombination as GoodCombination;
};

export const itemInGoodCombination = (
    gameStatus: GameStatusActionWithoutGame4,
    mainItemKey: keyof ItemsSelected,
    mainItemSelected: Item
): boolean => {
    const goodCombination = goodCombinationsData[gameStatus];
    return goodCombination.some(
        (combination) =>
            combination[`${mainItemKey}Id` as keyof typeof combination] ===
            mainItemSelected.id
    );
};

export const isItemDisabled = (
    gameStatus: GameStatusActionWithoutGame4,
    type: keyof ItemsSelected,
    item: Item,
    tryCount: TryCountInfo[]
): boolean => {
    const goodCombination = goodCombinationsData[gameStatus];
    const isItemInGoodCombination = goodCombination.some(
        (combination) =>
            combination[`${type}Id` as keyof typeof combination] === item.id
    );
    if (isItemInGoodCombination) return false;
    if (Object.values(tryCount).some((value) => value.mainItemId === item.id))
        return true;
    return false;
};

export const mainItemKeyConfig: Record<
    GameStatusActionWithoutGame4,
    keyof ItemsSelected
> = {
    [GAMESTATUS.GAME1ACTION]: 'products',
    [GAMESTATUS.GAME2ACTION]: 'objects',
    [GAMESTATUS.GAME3ACTION]: 'characters',
};

export const getVitalCardsByEnvironment = (
    selectedEnvironmentId: number,
    gameStatus: GameStatus
): Character[] => {
    const datasFromGameStatus = datasGameStatus(gameStatus);
    const vitalCards = datasFromGameStatus.characters.filter(
        (character: Character) => character.environmentsId === selectedEnvironmentId
    );
    return vitalCards;
};

/**
 * Replace the {@link Object.keys} function to be typed.
 * @param obj - The object to get the keys from.
 * @returns The keys of the object.
 */
export const objectKeys = <T extends object>(obj: T): Array<keyof T> => {
    return Object.keys(obj) as Array<keyof T>;
};

export const playSound = (audio: string): object => {
    const sound = new Audio(audio);
    return sound.play();
};

export const shuffle = <T>(array: T[]): T[] => {
    return array.sort(() => Math.random() - 0.5);
};

import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import {
    goToPreviousSituation,
    handleGoodCombination,
    skipOverlayMessage,
} from '@/services/games/game4/gameFourUtils';
import { useGame4Store } from '@/store/games/game4Store';

const RenderComponentGameAction4: React.FC = () => {
    const currentSituationIndex = useGame4Store.use.currentSituationIndex();

    const { overlayType, isCurrentOverlay } = useCurrentOverlay();

    return (
        <>
            {isCurrentOverlay ? (
                <MediatorAction
                    HandleFunctions={skipOverlayMessage}
                    title={
                        overlayType === 'error'
                            ? "Passer le message d'erreur"
                            : 'Passer le message de félicitations'
                    }
                    displayingCondition={true}
                    type='secondary'
                />
            ) : (
                <>
                    <MediatorAction
                        HandleFunctions={handleGoodCombination}
                        title={`Bonne réponse situation: ${currentSituationIndex + 1}`}
                        displayingCondition={true}
                        type='secondary'
                    />

                    <MediatorAction
                        HandleFunctions={goToPreviousSituation}
                        title='Revenir à la situation précédente'
                        displayingCondition={currentSituationIndex > 0}
                        type='secondary'
                    />
                </>
            )}
        </>
    );
};

export default RenderComponentGameAction4;

import { useEffect, useMemo, useState } from 'react';
import ActionButton from '@/features/devices/tablets/components/actionButton/ActionButton';
import CardTablet from '@/features/devices/tablets/components/card/Card';
import styles from '@/features/devices/tablets/components/card/cardContainer.module.scss';
import actionData from '@/data/actionDatas.json';
import dataGlobal from '@/data/global.json';
import { handleTabletStatus } from '@/services/global/globalHandleFunctions';
import {
    isItemDisabled,
    isObjectEmpty,
    mainItemKeyConfig,
    shuffle,
} from '@/services/global/globalUtils';
import {
    GAMESTATUS,
    type DeviceColor,
    type GameStatusActionWithoutGame4,
    type Item,
    type ItemsSelected,
} from '@/types/global/types';
import { useItemsFound } from '@/hooks/useItemsFound';
import { useItemsSelected } from '@/hooks/useItemsSelected';
import { useTryCount } from '@/hooks/useTryCount';
import { useGameStore } from '@/store/gameStore';

interface CardContainerTabletProps {
    deviceColor: DeviceColor;
    type: keyof ItemsSelected;
}

const CardContainerTablet: React.FC<CardContainerTabletProps> = ({
    deviceColor,
    type,
}) => {
    const currentGameStatus = useGameStore.use.gameStatus();

    const gameStatus = currentGameStatus as GameStatusActionWithoutGame4;

    const itemsSelected = useItemsSelected(gameStatus);
    const itemsFound = useItemsFound(gameStatus);
    const tryCount = useTryCount(gameStatus);

    const isSelectionGame1FullFilled =
        !isObjectEmpty(itemsSelected.products) &&
        itemsSelected.types.some((item) => item !== null) &&
        itemsSelected.risks.some((item) => item !== null);

    const isSelectionGame2FullFilled =
        !isObjectEmpty(itemsSelected.objects) &&
        !isObjectEmpty(itemsSelected.pathways);

    const isSelectionGame3FullFilled =
        !isObjectEmpty(itemsSelected.characters) &&
        !isObjectEmpty(itemsSelected.diseases) &&
        !isObjectEmpty(itemsSelected.environments);

    const items = actionData[gameStatus];

    const [itemsToDisplay, setItemsToDisplay] = useState<Item[]>([]);

    useEffect(() => {
        const shuffledItems = shuffle<Item>(items[type as keyof typeof items]);
        return setItemsToDisplay(shuffledItems);
    }, [items, type]);

    const isSelectionFullFilled: boolean = useMemo(() => {
        if (currentGameStatus === GAMESTATUS.GAME1ACTION)
            return isSelectionGame1FullFilled;
        if (currentGameStatus === GAMESTATUS.GAME2ACTION)
            return isSelectionGame2FullFilled;
        if (currentGameStatus === GAMESTATUS.GAME3ACTION)
            return isSelectionGame3FullFilled;
        return false;
    }, [
        currentGameStatus,
        isSelectionGame1FullFilled,
        isSelectionGame2FullFilled,
        isSelectionGame3FullFilled,
    ]);

    const onTabletClickEnvironement = () => {
        if (type !== 'environments') return;
        handleTabletStatus({
            red: true,
            green: true,
            blue: true,
            orange: true,
        });
    };

    return (
        <div className={styles.cardContainer}>
            {itemsToDisplay.map((item) => {
                const isItemsSelectedArray = Array.isArray(itemsSelected[type]);
                const isItemFound = itemsFound.some(
                    (currentItem) => currentItem?.slug === item.slug
                );
                const isMainItem = mainItemKeyConfig[gameStatus] === type;
                const isDisabled = isItemDisabled(gameStatus, type, item, tryCount);
                const isSelected = isItemsSelectedArray
                    ? (itemsSelected[type] as Item[]).some(
                          (currentItem) => currentItem?.id === item.id
                      )
                    : (itemsSelected[type] as Item).id === item.id;

                return (
                    <CardTablet
                        key={item.id}
                        item={item}
                        deviceColor={deviceColor}
                        type={type}
                        isSelected={isSelected}
                        isDisabled={isItemFound || (isDisabled && isMainItem)}
                        onClick={onTabletClickEnvironement}
                    />
                );
            })}
            {isSelectionFullFilled && (
                <ActionButton
                    title={dataGlobal.buttons.validate}
                    action='handleValidationSelection'
                    color={deviceColor}
                    style={{ position: 'absolute', bottom: '4%', zIndex: 1 }}
                />
            )}
        </div>
    );
};

export default CardContainerTablet;

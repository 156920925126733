import { useGame1Store } from '@/store/games/game1Store';
import { useGame2Store } from '@/store/games/game2Store';
import { useGame3Store } from '@/store/games/game3Store';
import { useMemo } from 'react';
import {
    GAMESTATUS,
    type GameStatusActionWithoutGame4,
    type ItemsSelected,
} from '@/types/global/types';

export const useItemsSelected = (gameStatus: GameStatusActionWithoutGame4) => {
    const itemsSelectedGame1 = useGame1Store.use.itemsSelected();
    const itemsSelectedGame2 = useGame2Store.use.itemsSelected();
    const itemsSelectedGame3 = useGame3Store.use.itemsSelected();

    const itemsSelected = useMemo(
        () => ({
            [GAMESTATUS.GAME1ACTION]: itemsSelectedGame1 as ItemsSelected,
            [GAMESTATUS.GAME2ACTION]: itemsSelectedGame2 as ItemsSelected,
            [GAMESTATUS.GAME3ACTION]: itemsSelectedGame3 as ItemsSelected,
        }),
        [itemsSelectedGame1, itemsSelectedGame2, itemsSelectedGame3]
    );

    return itemsSelected[gameStatus];
};

import globalData from '@/data/global.json';
import Card from '@/features/devices/tv/components/card/Card';
import VitalCard from '@/features/devices/tv/components/card/VitalCard';
import styles from '@/features/devices/tv/components/mainComponent/mainComponent.module.scss';
import { useItemsSelected } from '@/hooks/useItemsSelected';
import { useGameStore } from '@/store/gameStore';
import type { Character } from '@/types/games/game3/types';
import {
    GAMESTATUS,
    type GameStatusActionWithoutGame4,
    type Item,
    type ItemsSelected,
    type Product,
} from '@/types/global/types';

const MainComponent: React.FC = () => {
    const isThresholdReached = useGameStore.use.isThresholdReached();
    const currentGameStatus =
        useGameStore.use.gameStatus() as GameStatusActionWithoutGame4;

    const currentItemsSelected = useItemsSelected(
        currentGameStatus as GameStatusActionWithoutGame4
    );

    const getMappedItems = <T extends keyof ItemsSelected>(
        itemType: T
    ): ItemsSelected[T] => {
        return currentItemsSelected?.[itemType];
    };

    // Game 1
    const products = getMappedItems('products');
    const types = getMappedItems('types');
    const risks = getMappedItems('risks');

    // Game 2
    const pathways = getMappedItems('pathways');
    const objects = getMappedItems('objects');

    // Game 3
    const diseases = getMappedItems('diseases');
    const environments = getMappedItems('environments');
    const characters = getMappedItems('characters');

    const renderCard = (
        item: Product | Item | Character,
        title: string,
        variant: 'inner' | 'outline'
    ) => (
        <>
            {'cardNumber' in item ? (
                <>
                    {variant === 'outline' && (
                        <h1 className={styles.title}>{title}</h1>
                    )}
                    <VitalCard character={item} isSelected />
                </>
            ) : (
                <>
                    {variant === 'outline' && (
                        <h1 className={styles.title}>{title}</h1>
                    )}
                    <Card selectedItem={item} variant={variant} size='big' />
                </>
            )}
        </>
    );

    const renderItems = (
        items: Item[],
        title: string,
        variant: 'outline' | 'inner'
    ) => (
        <div>
            <h1 className={styles.title}>{title}</h1>
            <div>
                {Array.isArray(items) &&
                    items?.map((item, index) =>
                        item !== null ? (
                            <Card
                                key={index}
                                selectedItem={item}
                                variant={variant}
                                size='small'
                            />
                        ) : (
                            <div key={index} className={styles.placeholder} />
                        )
                    )}
            </div>
        </div>
    );

    const renderGame1 = () => (
        <div
            className={`${styles.mainComponentContainer} ${!isThresholdReached && styles.blurredFrame}`}
        >
            <div className={styles.productContainer}>
                {!Array.isArray(products) &&
                    renderCard(products, globalData.tv.productCardTitle, 'outline')}
            </div>
            <div className={styles.sideWrapper}>
                {renderItems(types, globalData.tv.typeCardTitle, 'outline')}
                {renderItems(risks, globalData.tv.riskCardTitle, 'outline')}
            </div>
        </div>
    );

    const renderGame2 = () => (
        <div className={`${styles.cardContainerWrapper}`}>
            <div className={styles.containerCardContainer}>
                {!Array.isArray(objects) &&
                    renderCard(objects, globalData.tv.objectCardTitle, 'inner')}
                {!Array.isArray(pathways) &&
                    renderCard(
                        pathways,
                        globalData.tv.penetrationPathCardTitle,
                        'inner'
                    )}
            </div>
            <h1>{globalData.tv.gameTwoContext}</h1>
        </div>
    );

    const renderGame3 = () => (
        <div className={`${styles.cardContainerWrapper}`}>
            <div className={styles.containerCardContainer}>
                {!Array.isArray(diseases) &&
                    renderCard(diseases, globalData.tv.diseaseCardTitle, 'inner')}
                {!Array.isArray(environments) &&
                    renderCard(
                        environments,
                        globalData.tv.environmentCardTitle,
                        'inner'
                    )}
                {/* Mettre bonne carte vitale */}
                {!Array.isArray(characters) && renderCard(characters, '', 'inner')}
            </div>
            <h1>{globalData.tv.gameThreeContext}</h1>
        </div>
    );

    const gameRenderers = {
        [GAMESTATUS.GAME1ACTION]: renderGame1,
        [GAMESTATUS.GAME2ACTION]: renderGame2,
        [GAMESTATUS.GAME3ACTION]: renderGame3,
    };

    return gameRenderers[currentGameStatus]();
};

export default MainComponent;

import { motion } from 'framer-motion';
import styles from '@/features/devices/tablets/mediator/mediatorAction.module.scss';

export type MediatorActionProps = {
    HandleFunctions: () => void;
    title: string;
    description?: string;
    displayingCondition?: boolean;
    type?: 'secondary' | 'tertiary';
};

const MediatorAction: React.FC<MediatorActionProps> = ({
    HandleFunctions,
    title,
    description,
    displayingCondition,
    type,
}) => {
    if (!displayingCondition) return;
    return (
        <div className={styles.mediatorAction}>
            <div>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
            <motion.button
                whileTap={{ scale: 0.85 }}
                onClick={HandleFunctions}
                className={type && styles[type]}
            >
                <p> Exécuter </p>
            </motion.button>
        </div>
    );
};

export default MediatorAction;

import createSelectors from '@/store/selectors';
import {
    resetStoreProperty,
    updateItemsFound,
    updateItemsSelected,
    updateTryCount,
} from '@/store/storeUtils';
import { deepCopy, INITIAL_GAME3_STORE } from '@/sessionInfo/sessionInfo';
import type { Game3ItemsSelected } from '@/types/games/game3/types';
import type { Item } from '@/types/global/types';
import { create } from 'zustand';

const useGame3StoreBase = create(() => deepCopy(INITIAL_GAME3_STORE));

export const useGame3Store = createSelectors(useGame3StoreBase);

export const setItemsSelectedGame3 = (
    itemKey: keyof Game3ItemsSelected,
    item: Item
) => updateItemsSelected(itemKey, item, useGame3StoreBase);

export const resetItemsSelectedGame3 = () =>
    resetStoreProperty('itemsSelected', useGame3StoreBase, INITIAL_GAME3_STORE);

export const setItemsFoundGame3 = (item: Item) =>
    updateItemsFound(item, useGame3StoreBase);

export const resetItemsFoundGame3 = () =>
    resetStoreProperty('itemsFound', useGame3StoreBase, INITIAL_GAME3_STORE);

export const setTryCountGame3 = (id: number) => {
    updateTryCount(id, useGame3StoreBase);
};

export const resetTryCountGame3 = () =>
    resetStoreProperty('tryCount', useGame3StoreBase, INITIAL_GAME3_STORE);

export const resetGame3Store = () => {
    useGame3StoreBase.setState(deepCopy(INITIAL_GAME3_STORE));
};

import BlueActionSwitch from '@/features/devices/tablets/blue/BlueActionSwitch';
import GreenActionSwitch from '@/features/devices/tablets/green/GreenActionSwitch';
import MediatorTablet from '@/features/devices/tablets/mediator/MediatorTablet';
import OrangeActionSwitch from '@/features/devices/tablets/orange/OrangeActionSwitch';
import RedActionSwitch from '@/features/devices/tablets/red/RedActionSwitch';
import Tv from '@/features/devices/tv/Tv';
import CreateRoom from '@/features/reusablecomponents/global/waitingPlayers/CreateRoom';
import {
    handleGoToGameStatus,
    handleUpdateDeviceActivatedStatus,
} from '@/services/global/globalHandleFunctions';
import { emitSocketEvent, isDevelopperMode } from '@/services/global/globalUtils';
import SocketService from '@/services/SocketService';
import {
    resetDevice,
    setDeviceActivatedStatus,
    setDeviceConnectedStatus,
} from '@/store/deviceStore';
import {
    resetGame1Store,
    resetItemsFoundGame1,
    resetItemsSelectedGame1,
    resetTryCountGame1,
    setItemsFoundGame1,
    setItemsSelectedGame1,
    setTryCountGame1,
} from '@/store/games/game1Store';
import {
    resetGame2Store,
    resetItemsFoundGame2,
    resetItemsSelectedGame2,
    resetTryCountGame2,
    setItemsFoundGame2,
    setItemsSelectedGame2,
    setTryCountGame2,
} from '@/store/games/game2Store';
import {
    resetGame3Store,
    resetItemsFoundGame3,
    resetItemsSelectedGame3,
    resetTryCountGame3,
    setItemsFoundGame3,
    setItemsSelectedGame3,
    setTryCountGame3,
} from '@/store/games/game3Store';
import {
    resetGame4Store,
    resetPlayersAnswerGame4,
    setPlayersAnswerGame4,
    setSituationIndexGame4,
} from '@/store/games/game4Store';
import {
    resetGame,
    setGameResult,
    setGameStatus,
    setIsAudioPlaying,
    setIsThresholdReached,
} from '@/store/gameStore';
import {
    resetOverlays,
    setCurrentOverlayDescriptions,
    setCurrentOverlayIndex,
} from '@/store/overlayStore';
import { setRoomName } from '@/store/roomStore';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import type { Game1ItemsSelected } from '@/types/games/game1/types';
import type { Game2ItemsSelected } from '@/types/games/game2/types';
import type { Game3ItemsSelected } from '@/types/games/game3/types';
import type { DeviceColorWithTvAndMediator, GameStatus, OverlayDescription } from '@/types/global/types';
import { GAMESTATUS } from '@/types/global/types';

const App: React.FC = () => {
    const [room, setRoom] = useState('');
    const socket = SocketService.getInstance().getSocket();
    const [isFirstRender, setIsFirstRender] = useState(true);

    const resetGames = useCallback(() => {
        resetOverlays();
        resetDevice();
        resetGame();
        resetGame1Store();
        resetGame2Store();
        resetGame3Store();
        resetGame4Store();
    }, []);

    useEffect(() => {
        if (!isFirstRender) {
            return;
        }
        const pathSegment = window.location.pathname.split('/')[1];
        const currentDevice = pathSegment.split(
            '-'
        )[0] as DeviceColorWithTvAndMediator;

        if (!isDevelopperMode) return;

        setRoomName('devRoom');
        setDeviceConnectedStatus(currentDevice, true);
        setRoom('devRoom');
        emitSocketEvent('send_join_room', {
            device: currentDevice,
        });
        setIsFirstRender(false);
    }, [isFirstRender]);

    useEffect(() => {
        socket.on('receive_start_game', (data) => {
            setDeviceConnectedStatus(data.color, true);
            if (data.color === 'mediator') {
                handleUpdateDeviceActivatedStatus(data.color, true);
                handleGoToGameStatus(GAMESTATUS.WELCOME_SCREEN);
            }
        });
        socket.on('receive_game_status', (data) => {
            setGameStatus(data.status);
        });
        socket.on('receive_device_activated_status', (data: { deviceColor: any; status: boolean; }) => {
            setDeviceActivatedStatus(data.deviceColor, data.status);
        });
        socket.on('receive_is_audio_playing', (data: { isAudioPlaying: boolean; }) => {
            setIsAudioPlaying(data.isAudioPlaying);
        });
        socket.on('receive_update_overlay_index', (data: { overlayIndex: number; }) => {
            setCurrentOverlayIndex(data.overlayIndex);
        });
        socket.on('receive_update_overlay_descriptions', (data: { overlayDescriptions: OverlayDescription[]; overlayType: any; }) => {
            setCurrentOverlayDescriptions(
                data.overlayDescriptions,
                data.overlayType
            );
            setGameResult('');
        });
        socket.on('receive_reset', () => {
            resetGames();
        });
        socket.on('receive_reset_items_selected', (data: { gameStatus: any; }) => {
            switch (data.gameStatus) {
                case GAMESTATUS.GAME1ACTION:
                    resetItemsSelectedGame1();
                    break;
                case GAMESTATUS.GAME2ACTION:
                    resetItemsSelectedGame2();
                    break;
                case GAMESTATUS.GAME3ACTION:
                    resetItemsSelectedGame3();
                    break;
                default:
                    break;
            }
        });
        socket.on('receive_reset_items_game_4', () => {
            resetPlayersAnswerGame4();
        });
        socket.on('receive_reset_items_found', (data: { gameStatus: any; }) => {
            switch (data.gameStatus) {
                case GAMESTATUS.GAME1ACTION:
                    resetItemsSelectedGame1();
                    resetItemsFoundGame1();
                    break;
                case GAMESTATUS.GAME2ACTION:
                    resetItemsSelectedGame2();
                    resetItemsFoundGame2();
                    break;
                case GAMESTATUS.GAME3ACTION:
                    resetItemsSelectedGame3();
                    resetItemsFoundGame3();
                    break;
                default:
                    break;
            }
        });
        socket.on('receive_update_item_selected', (data) => {
            switch (data.gameStatus) {
                case GAMESTATUS.GAME1ACTION:
                    setItemsSelectedGame1(
                        data.itemKey as keyof Game1ItemsSelected,
                        data.item
                    );
                    break;
                case GAMESTATUS.GAME2ACTION:
                    setItemsSelectedGame2(
                        data.itemKey as keyof Game2ItemsSelected,
                        data.item
                    );

                    break;
                case GAMESTATUS.GAME3ACTION:
                    setItemsSelectedGame3(
                        data.itemKey as keyof Game3ItemsSelected,
                        data.item
                    );
                    break;
                default:
                    break;
            }
        });
        socket.on('receive_update_item_found', (data: { gameStatus: any; item: any; }) => {
            switch (data.gameStatus) {
                case GAMESTATUS.GAME1ACTION:
                    setItemsFoundGame1(data.item);
                    break;
                case GAMESTATUS.GAME2ACTION:
                    setItemsFoundGame2(data.item);
                    break;
                case GAMESTATUS.GAME3ACTION:
                    setItemsFoundGame3(data.item);
                    break;
                default:
                    break;
            }
        });
        socket.on('receive_update_try_count', (data: { gameStatus: any; id: number; }) => {
            switch (data.gameStatus) {
                case GAMESTATUS.GAME1ACTION:
                    setTryCountGame1(data.id);
                    break;
                case GAMESTATUS.GAME2ACTION:
                    setTryCountGame2(data.id);
                    break;
                case GAMESTATUS.GAME3ACTION:
                    setTryCountGame3(data.id);
                    break;
            }
        });
        socket.on('receive_reset_try_count', (data: { gameStatus: any; }) => {
            switch (data.gameStatus) {
                case GAMESTATUS.GAME1ACTION:
                    resetTryCountGame1();
                    break;
                case GAMESTATUS.GAME2ACTION:
                    resetTryCountGame2();
                    break;
                case GAMESTATUS.GAME3ACTION:
                    resetTryCountGame3();
                    break;
                default:
                    break;
            }
        });
        socket.on('receive_is_threshold_reached', (data: { isThresholdReached: boolean; }) => {
            setIsThresholdReached(data.isThresholdReached);
        });
        socket.on('receive_update_players_answer_game_4', (data: { deviceColor: any; value: boolean; }) => {
            setPlayersAnswerGame4(data.deviceColor, data.value);
        });
        socket.on('receive_update_current_situation_index_game_4', (data: { currentSituationIndex: number; }) => {
            setSituationIndexGame4(data.currentSituationIndex);
        });
        socket.on('receive_reset_items_game_4', () => {
            resetPlayersAnswerGame4();
        });
        return () => {
            socket.off('receive_game_status');
            socket.off('receive_device_activated_status');
            socket.off('receive_reset');
            socket.off('receive_is_audio_playing');
            socket.off('receive_reset_items_selected');
            socket.off('receive_reset_items_found');
            socket.off('receive_update_item_selected');
            socket.off('receive_update_item_found');
            socket.off('receive_update_try_count');
            socket.off('receive_update_players_answer_game_4');
            socket.off('receive_update_current_situation_index_game_4');
        };
    }, [socket]);

    const router = createBrowserRouter([
        {
            path: '/tv',
            element: room ? <Tv /> : <CreateRoom color='tv' setRoom={setRoom} />,
        },
        {
            path: '/red-tablet',
            element: room ? (
                <RedActionSwitch />
            ) : (
                <CreateRoom color='red' setRoom={setRoom} />
            ),
        },
        {
            path: '/blue-tablet',
            element: room ? (
                <BlueActionSwitch />
            ) : (
                <CreateRoom color='blue' setRoom={setRoom} />
            ),
        },
        {
            path: '/green-tablet',
            element: room ? (
                <GreenActionSwitch />
            ) : (
                <CreateRoom color='green' setRoom={setRoom} />
            ),
        },
        {
            path: '/orange-tablet',
            element: room ? (
                <OrangeActionSwitch />
            ) : (
                <CreateRoom color='orange' setRoom={setRoom} />
            ),
        },
        {
            path: '/mediator-tablet',
            element: room ? (
                <MediatorTablet roomName={room} />
            ) : (
                <CreateRoom color='mediator' setRoom={setRoom} />
            ),
        },
    ]);

    return <RouterProvider router={router} />;
};

export default App;

import createSelectors from '@/store/selectors';
import { deepCopy, INITAL_OVERLAY_STORE } from '@/sessionInfo/sessionInfo';
import { type OverlayDescription, type OverlayType } from '@/types/global/types';
import { create } from 'zustand';

const useOverlayStoreBase = create(() => deepCopy(INITAL_OVERLAY_STORE));

export const useOverlayStore = createSelectors(useOverlayStoreBase);

export const setCurrentOverlayIndex = (currentOverlayIndex: number) =>
    useOverlayStoreBase.setState({
        currentOverlayIndex,
    });

export const setCurrentOverlayDescriptions = (
    overlayDescriptions: OverlayDescription[],
    overlayType: OverlayType
) => {
    useOverlayStoreBase.setState({
        overlayDescriptions,
        overlayType,
        currentOverlayIndex: 0,
    });
};

export const setCurrentOverlayType = (overlayType: OverlayType) =>
    useOverlayStoreBase.setState({
        overlayType,
    });

export const resetOverlays = () =>
    useOverlayStoreBase.setState(deepCopy(INITAL_OVERLAY_STORE));

import actionsContent from '@/data/actionDeviceHeaderContent.json';
import globalData from '@/data/global.json';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import { type DeviceColor, type GameStatusAction } from '@/types/global/types';

const useCurrentTitleHeaderTablet = (color: DeviceColor): string => {
    const currentGameStatus = useGameStore.use.gameStatus();
    const overlayType = useOverlayStore.use.overlayType();

    const overlayTypeMapping = {
        error: globalData.header.error,
        success: globalData.header.success,
    };

    if (overlayType === 'error' || overlayType === 'success') {
        return overlayTypeMapping[overlayType];
    }
    return actionsContent[currentGameStatus as GameStatusAction]?.[color]
        ?.headerContent.title;
};

export default useCurrentTitleHeaderTablet;

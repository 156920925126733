import type React from 'react';
import styles from '@/features/devices/tablets/mediator/preConnectionMediatorScreen.module.scss';

interface deviceStatus {
    label: string;
    status: boolean;
    color: string;
}

interface PreConnectionMediatorScreenProps {
    deviceStatuses: deviceStatus[];
    handleConnect: () => void;
}

const PreConnectionMediatorScreen: React.FC<PreConnectionMediatorScreenProps> = ({
    deviceStatuses,
    handleConnect,
}) => {
    const allDevicesConnected = deviceStatuses.find((device) => !device.status);
    return (
        <div className={styles.container}>
            <div className={styles.devicesList}>
                <h2>Tablettes en ligne</h2>
                <div>
                    {deviceStatuses
                        .filter((tablet) => tablet.status)
                        .map((tablet) => (
                            <p key={tablet.label}>
                                <span
                                    className={`${
                                        styles[
                                            'tablet-color-' +
                                                tablet.color.toLowerCase()
                                        ]
                                    }`}
                                ></span>
                                {tablet.label}
                            </p>
                        ))}
                </div>

                <h2>Tablettes hors ligne</h2>
                <div>
                    {deviceStatuses
                        .filter((tablet) => !tablet.status)
                        .map((tablet) => (
                            <p key={tablet.label} className={styles.offline}>
                                <span
                                    className={`${
                                        styles[
                                            'tablet-color-' +
                                                tablet.color.toLowerCase()
                                        ]
                                    }`}
                                ></span>
                                {tablet.label}
                            </p>
                        ))}
                </div>
            </div>
            {allDevicesConnected === undefined && (
                <div className={styles.buttonContainer}>
                    <button onClick={handleConnect}>LANCER LA SESSION</button>
                </div>
            )}
        </div>
    );
};

export default PreConnectionMediatorScreen;

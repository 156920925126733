import { CardClick } from '@/assets/sounds/soundsEffect';
import styles from '@/features/devices/tablets/components/card/card.module.scss';
import { handleItemSelected } from '@/services/global/globalHandleFunctions';
import { playSound } from '@/services/global/globalUtils';
import { useGameStore } from '@/store/gameStore';
import { motion } from 'framer-motion';
import {
    type DeviceColor,
    type GameStatusActionWithoutGame4,
    type Item,
    type ItemsSelected,
} from '@/types/global/types';

interface CardTabletProps {
    item: Item;
    deviceColor: DeviceColor;
    type: keyof ItemsSelected;
    isSelected?: boolean;
    isDisabled?: boolean;
    onClick?: () => void;
}

const CardTablet: React.FC<CardTabletProps> = ({
    item,
    type,
    deviceColor,
    isSelected,
    isDisabled,
    onClick,
}) => {
    const currentGameStatus =
        useGameStore.use.gameStatus() as GameStatusActionWithoutGame4;

    return (
        <motion.button
            className={`${
                isSelected ? styles[`${deviceColor}CardPressed`] : undefined
            } ${styles.cardContainer}`}
            whileTap={{
                scale: 0.9,
            }}
            onClick={() => {
                handleItemSelected(item, type, currentGameStatus);
                playSound(CardClick);
                onClick?.();
            }}
            disabled={isDisabled}
        >
            <p>{item.name}</p>
            <div>
                <img draggable={false} src={item.image} alt='placeholder' />
            </div>
        </motion.button>
    );
};

export default CardTablet;

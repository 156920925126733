import createSelectors from '@/store/selectors';
import {
    resetStoreProperty,
    updateItemsFound,
    updateTryCount,
} from '@/store/storeUtils';
import { deepCopy, INITIAL_GAME1_STORE } from '@/sessionInfo/sessionInfo';
import type { Game1ItemsSelected } from '@/types/games/game1/types';
import type { Item } from '@/types/global/types';
import { create } from 'zustand';

const useGame1StoreBase = create(() => deepCopy(INITIAL_GAME1_STORE));

export const useGame1Store = createSelectors(useGame1StoreBase);

export const setItemsSelectedGame1 = (
    itemKey: keyof Game1ItemsSelected,
    item: Item
) => {
    useGame1StoreBase.setState((state) => {
        const newItemsSelected = { ...state.itemsSelected };

        if (itemKey === 'products') {
            newItemsSelected.products = item;
        } else {
            const items = newItemsSelected[itemKey];

            const index = items.findIndex(
                (selectedItem: { id: number; }) => selectedItem?.id === item.id
            );

            if (index !== -1) {
                items[index] = null as unknown as Item;
            } else {
                const firstEmptyIndex = items.findIndex(
                    (selectedItem) => selectedItem == null
                );
                if (firstEmptyIndex !== -1) {
                    items[firstEmptyIndex] = item;
                }
            }

            newItemsSelected[itemKey] = items;
        }

        return {
            itemsSelected: deepCopy(newItemsSelected),
        };
    });
};

export const resetItemsSelectedGame1 = () =>
    resetStoreProperty('itemsSelected', useGame1StoreBase, INITIAL_GAME1_STORE);

export const setItemsFoundGame1 = (item: Item) =>
    updateItemsFound(item, useGame1StoreBase);

export const resetItemsFoundGame1 = () =>
    resetStoreProperty('itemsFound', useGame1StoreBase, INITIAL_GAME1_STORE);

export const setTryCountGame1 = (id: number) =>
    updateTryCount(id, useGame1StoreBase);

export const resetTryCountGame1 = () =>
    resetStoreProperty('tryCount', useGame1StoreBase, INITIAL_GAME1_STORE);

export const resetGame1Store = () =>
    useGame1StoreBase.setState(deepCopy(INITIAL_GAME1_STORE));

import { useGame1Store } from '@/store/games/game1Store';
import { useGame2Store } from '@/store/games/game2Store';
import { useGame3Store } from '@/store/games/game3Store';
import { useMemo } from 'react';
import {
    GAMESTATUS,
    type GameStatusActionWithoutGame4,
    type TryCountInfo,
} from '@/types/global/types';

export const useTryCount = (
    gameStatus: GameStatusActionWithoutGame4
): TryCountInfo[] => {
    const tryCountGame1 = useGame1Store.use.tryCount();
    const tryCountGame2 = useGame2Store.use.tryCount();
    const tryCountGame3 = useGame3Store.use.tryCount();

    const itemsFound = useMemo(
        () => ({
            [GAMESTATUS.GAME1ACTION]: tryCountGame1,
            [GAMESTATUS.GAME2ACTION]: tryCountGame2,
            [GAMESTATUS.GAME3ACTION]: tryCountGame3,
        }),
        [tryCountGame1, tryCountGame2, tryCountGame3]
    );

    return itemsFound[gameStatus];
};

import styles from '@/features/reusablecomponents/text/textAnimation.module.scss';

interface TextAnimationProps {
    text: string;
}

const TextAnimation: React.FC<TextAnimationProps> = ({ text }) => {
    const randomNumber = Math.floor(Math.random() * 100);

    return (
        <p key={text}>
            {text?.split(' ').map((word: string, i: number) => (
                <span
                    key={`${word}${i}${randomNumber}`}
                    className={styles[`fadeInText-${i}`]}
                >
                    {word}{' '}
                </span>
            ))}
        </p>
    );
};

export default TextAnimation;

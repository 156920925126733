import styles from '@/features/devices/tablets/mediator/statusIndicator.module.scss';

interface StatusIndicatorProps {
    status: boolean;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status }) => {
    const statusClass = status ? styles.connectedStatus : styles.disconnectedStatus;

    return (
        <div className={styles.statusContainer}>
            <div className={`${styles.status} ${statusClass}`} />
            {status ? 'En ligne ' : 'Déconnecté'}
        </div>
    );
};

export default StatusIndicator;
